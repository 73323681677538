import React from 'react'
import './Ciation.css';
import Qimg from '../../../../assets/Qimg.svg'

function Ciation() {
  return (
    <div className='ciation'>
      <div className='ciation-container'>
        <div className='ciation-title'>
          <div className='ciation-txt-section'>
            <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>No long-term contracts No catches</h2>
            <p className='ciation-subtxt'>Start your 30-day free trial today.</p>
            <div className='ciation-btn'>
              <button type="" className='cblu-bck'>Learn more</button>
              <button type="" className='cbig-wht'>Get started</button>
            </div>
          </div>
          <div className='ciation-img-section'>
            <img src={Qimg} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ciation