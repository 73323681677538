import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SVGS } from "../../assets";
import HeaderLogo from "../../assets/HeaderLogo.svg";
import FormGroupLogin from "./FormGroup.login";

function LeftGridLogin() {
  const [thisYear, setThisYear] = useState("");

  useEffect(() => {
    const date = new Date();
    setThisYear(date.getFullYear());
  }, []);

  return (
    <div className="w-full h-full px-8 py-6 flex flex-col justify-between">
      <nav className="w-full h-12 flex items-center">
        <Link to="/" className='flex gap-2 items-center'>
          {/* <SVGS.BrandLogo /> */}
          <img src={HeaderLogo} alt="" />
          <p className='text-[30px] text-primary-700 font-bold'>PayMoor</p>
        </Link>
      </nav>
      <div className="formgroup__container flex justify-center">
        <FormGroupLogin />
      </div>
      <footer className="flex items-center justify-between">
        <p className="text-sm text-gray-600 flex items-center gap-1">
          © PayMoor {thisYear}
        </p>
        <p className="text-sm text-gray-600 flex items-center gap-1">
          <SVGS.EmailIcon /> help@paymoor.com
        </p>
      </footer>
    </div>
  );
}

export default LeftGridLogin;
