import React, { useEffect, useState } from "react";
import { Icons } from "../../assets/icons";
import { SVGS } from "../../assets";
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import { useRolesRequest } from "../../services/request-hooks/roles/useRoles";
import TableRow from "./TableRow";


function ManageRolesOutlet() {
    const [permissionSelectOptions, setPermissionSelectOption] = useState([]);
    const { viewPermissions: _viewAllPermissions } = useRolesRequest();
    const viewAllPermissions = async () => {
        const _permissions = await _viewAllPermissions();
        setPermissionSelectOption(_permissions);
    };
    useEffect(() => {
        viewAllPermissions();
    }, []);


    const [rolesData, setRolesData] = useState([]);
    const { viewAll: _viewAllRoles } = useRolesRequest();
    const viewAllRoles = async () => {
        const _roles = await _viewAllRoles();
        setRolesData(_roles.contents);
    };
    useEffect(() => {
        viewAllRoles();
    }, []);

    const [modalName, setModalName] = useState("");
    const [rolesPayload, setRolesPayload] = useState({
        name: "",
        description: "",
        roletype: "",
        permissions: []
    });

    const onCreateRolesInputChange = (e) => {
        const { name, value } = e.target;
        setRolesPayload({
            ...rolesPayload,
            [name]: value,
        });
        console.log(rolesPayload)
        console.log(e.target.value)
    };

    const createRoles = () => {
        setModalName('create-roles')
    };
    const hideOverlay = () => setModalName("");
    return (
        <React.Fragment>
            <OverlayProvider
                rolesPayload={rolesPayload}
                // createRolesPayload={createRolesPayload}
                // setCreateRolesPayload={setCreateRolesPayload}
                onCreateRolesInputChange={onCreateRolesInputChange}
                modalName={modalName}
                setPermissionSelectOption={setPermissionSelectOption}
                permissionSelectOptions={permissionSelectOptions}
                setModalName={setModalName}
                hideOverlay={hideOverlay}
                rolesData={rolesData}
            >
                <div className="w-full flex items-center flex-wrap gap-2 mt-6">
                    <button onClick={createRoles} className="bg-[#EFF2FA] text-gray-700 px-7 min-w-[294px] h-16 rounded-xl flex items-center justify-between font-medium ...">
                        <span className="flex items-center text-[16px] gap-x-3">
                            <Icons.ManageRolesIcon color={"#2B4696"} />
                            Create New Role
                        </span>
                        <SVGS.PlusIcon color={"#2B4696"} />
                    </button>
                </div>
                <div className="w-full mt-10">
                    <div className="user-table-container w-full h-[60vh] mt-6 px-7 py-2.5 bg-gray-50">
                        {rolesData?.map((roles) => (
                            <TableRow key={roles.id} roles={roles} />
                        ))}
                    </div>
                </div>
            </OverlayProvider>
        </React.Fragment>
    )
}

export default ManageRolesOutlet