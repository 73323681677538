import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import TextInput from "../../input/TextInput";
import PasswordInput from "../../input/PasswordInput";
import PrimaryButton from "../../button/PrimaryButton";
import SelectInput from "../../select/SelectInput";
import { useUsersRequest } from "../../../services/request-hooks/users/useUsers";

function Index() {
  const { create } = useUsersRequest();
  const {
    fetchUsers,
    hideOverlay,
    setModalName,
    createAdminPayload,
    adminRolesSelectOptions,
    onCreateAdminInputChange,
  } = React.useContext(OverlayContext);
  const [isBtnActive, setIsBtnActive] = useState(false);

  useEffect(() => {
    let isAnyKeyEmpty = false;
    Object.keys(createAdminPayload).forEach((key) => {
      if (createAdminPayload[key] === "") isAnyKeyEmpty = true;
    });
    if (isAnyKeyEmpty) {
      setIsBtnActive(false);
    } else {
      setIsBtnActive(true);
    }
  }, [createAdminPayload]);

  const createAdminHandler = async () => {
    const response = await create(createAdminPayload);
    if (response?.status) {
      fetchUsers();
      setModalName("create-admin-success");
    }
  };
  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="header flex items-center h-[60px] justify-between">
          <h5 className="text-xl font-semibold">Create Admin account</h5>
          <button
            onClick={hideOverlay}
            className="bg-gray-50 w-9 h-9 rounded flex items-center justify-center"
          >
            <SVGS.CloseIcon />
          </button>
        </div>
        <hr className="bg-gray-200 mt-2" />
        <div className="form-body mt-6">
          <div className="mb-4">
            <TextInput
              value={createAdminPayload.firstName}
              onChange={onCreateAdminInputChange}
              name="firstName"
              label={"Admin Firstname"}
              placeholder="Enter Firstname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAdminPayload.lastName}
              onChange={onCreateAdminInputChange}
              name="lastName"
              label={"Admin Lastname"}
              placeholder="Enter Lastname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAdminPayload.username}
              onChange={onCreateAdminInputChange}
              name="username"
              label={"Admin username"}
              placeholder="Enter Username"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAdminPayload.emailAddress}
              onChange={onCreateAdminInputChange}
              name="emailAddress"
              label={"Admin email address"}
              placeholder="Enter Email Address"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <SelectInput
              options={adminRolesSelectOptions}
              value={createAdminPayload.roleIdentifier}
              onChange={onCreateAdminInputChange}
              name="roleIdentifier"
              label={"Admin role"}
              placeholder="Assign role to user"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <PasswordInput
              value={createAdminPayload.password}
              onChange={onCreateAdminInputChange}
              name="password"
              label={"Admin temporary password"}
              placeholder="Enter Temporary Password"
              widthBackground={true}
            />
            <button className="text-primary-700 mt-1 font-semibold text-sm">
              Generate password
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <PrimaryButton
          onClick={createAdminHandler}
          isLoading={false}
          isActive={isBtnActive}
          text="Create Admin account"
        />
      </div>
    </div>
  );
}

export default Index;
