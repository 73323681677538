import React from 'react';
import { RiWechatLine } from 'react-icons/ri';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs'
import Fimg from '../../../../assets/Fimg.svg';
import { IconContext } from 'react-icons/lib';

function Features() {
    return (
        <div>
            <div className='mx-auto px-[5px] sm:px-[25px] md:px-[45px] lg:px[100px] pt-[200px]'>
                <div className='flex flex-col gap-5'>
                    <p className=' font-semibold text-[#2b4696] text-xl leading-6'>Features</p>
                    <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>Financial software to fuel your growth</h2>
                    <p className='font-semibold text-[#475467] text-xl leading-10'>Spend smarter, lower your bills, get cashback on everything you buy, and unlock credit to grow your business.</p>
                </div>

                <div className='flex flex-col md:flex-row lg:justify-between gap-11 mt-14'>
                    <div className=' pt-10'>
                        <div className='flex flex-col gap-3'>
                            <div className='flex gap-10'>
                                <div className='bg-[#c0cdf5] w-12 h-12 rounded-full border-8 border-[#d9e1f9] flex justify-between items-center text-center'>
                                    <div className='flex items-center justify-center text-center pl-[3px]'>
                                        <IconContext.Provider value={{ size: '1.5rem', color: '#3658BB', }}>
                                            <RiWechatLine />
                                        </IconContext.Provider>
                                    </div>
                                </div>
                                <div className=' pt-2 w-4/5 flex flex-col gap-1'>
                                    <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Unlimited cards</h3>
                                    <p className=' font-normal text-[16px] leading-6 text-[#475467]'>Give your team the autonomy they need with access to as many cards as they need. Authorise purchases with a click. Simple.</p>
                                    <div className='flex items-center text-center gap-1 font-semibold text-base leading-6 text-[#2b4696] cursor-pointer'>
                                        <p>Learn more</p>
                                        <BsArrowRightShort />
                                    </div>
                                </div>
                            </div>

                            <div className='flex gap-10'>
                                <div className='bg-[#c0cdf5] w-12 h-12 rounded-full border-8 border-[#d9e1f9] flex justify-between items-center text-center'>
                                    <div className='flex items-center justify-center text-center pl-[3px]'>
                                        <IconContext.Provider value={{ size: '1.5rem', color: '#3658BB', }}>
                                            <AiOutlineDollarCircle />
                                        </IconContext.Provider>
                                    </div>
                                </div>
                                <div className=' pt-2 w-4/5 flex flex-col gap-1'>
                                    <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Easy expense policies</h3>
                                    <p className=' font-normal text-[16px] leading-6 text-[#475467]'>Every card comes with configurable spending limits, purchase restrictions, and cancellations for each employee and team.</p>
                                    <div className='flex items-center text-center gap-1 font-semibold text-base leading-6 text-[#2b4696] cursor-pointer'>
                                        <p>Learn more</p>
                                        <BsArrowRightShort />
                                    </div>
                                </div>
                            </div>

                            <div className='flex gap-10'>
                                <div className='bg-[#c0cdf5] w-12 h-12 rounded-full border-8 border-[#d9e1f9] flex justify-between items-center text-center'>
                                    <div className='flex items-center justify-center text-center pl-[7px]'>
                                        <IconContext.Provider value={{ size: '1rem', color: '#3658BB', }}>
                                            <BsBoxArrowUpRight />
                                        </IconContext.Provider>
                                    </div>
                                </div>
                                <div className=' pt-2 w-4/5 flex flex-col gap-1'>
                                    <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Advanced analytics</h3>
                                    <p className=' font-normal text-[16px] leading-6 text-[#475467]'>An all-in-one platform that helps you balance everything your team need to be happy and your finances in order.</p>
                                    <div className='flex items-center text-center gap-1 font-semibold text-base leading-6 text-[#2b4696] cursor-pointer'>
                                        <p>Learn more</p>
                                        <BsArrowRightShort />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <img src={Fimg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features