import { useRef } from 'react';
import './Quote.css';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FiArrowUpRight } from 'react-icons/fi';
import { IconContext } from 'react-icons/lib';
import Layer from '../../../../assets/Layer.svg';
import Sisyphus from '../../../../assets/Sisyphus.svg';
import Capsule from '../../../../assets/Capsule.svg'
import Hourglass from '../../../../assets/Hourglass.svg'
import Catalog from '../../../../assets/Catalog.svg'
import Quotient from '../../../../assets/Quotient.svg'
import Command from '../../../../assets/Command+R.svg'

function Quote() {
  const navigate = useNavigate();

  const quoteBoxRef = useRef(null)

  const scrollLeft = () => {
    quoteBoxRef.current.scrollLeft -= 400
  }
  const scrollRight = () => {
    quoteBoxRef.current.scrollLeft += 400
  }

  return (
    <div>
      <div className='quote-container'>
        <div className='quote-header'>
          <div className='quote-title'>
            <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>We’ve helped hundreds of global companies</h2>
            <p className='quote-title-subtxt'>Case studies from some of our amazing customers who are building faster.</p>
          </div>
          <div className='quote-btn'>
            <button type="" className="qbig-wht">Our customers</button>
            <button  onClick={() => navigate('/register')} type="" className="qblu-bck">Create account</button>
          </div>
        </div>

        <div className='quote-card-section' ref={quoteBoxRef}>
          <div style={{ width: "384px", height: "504px", background: "#3658BB", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Layer} alt="" />
              <p className='quote-card-header-txt'>Layer</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Layers</p>
                <p className='quote-card-info-sub-txt'>“PayMoor has saved us thousands of hours of work. We’re able to spin up projects faster.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#039855", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Sisyphus} alt="" />
              <p className='quote-card-header-txt'>Sisyphus</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Sisyphus</p>
                <p className='quote-card-info-sub-txt'>“We’ve been using PayMoor to kick start every new project and can’t work without it.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#1570EF", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Capsule} alt="" />
              <p className='quote-card-header-txt'>Capsule</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Capsule</p>
                <p className='quote-card-info-sub-txt'>“Love the simplicity of the service and the prompt customer support.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#444CE7", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Catalog} alt="" />
              <p className='quote-card-header-txt'>Catalog</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Catalog</p>
                <p className='quote-card-info-sub-txt'>“PayMoor has saved us thousands of hours of work. We’re able to spin up projects faster.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#DD2590", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Quotient} alt="" />
              <p className='quote-card-header-txt'>Quotient</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Quotient</p>
                <p className='quote-card-info-sub-txt'>“We’ve been using Untitled to kick start every new project and can’t work without it.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#0086C9", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Hourglass} alt="" />
              <p className='quote-card-header-txt'>Hourglass</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Hourglass</p>
                <p className='quote-card-info-sub-txt'>“Love the simplicity of the service and the prompt customer support.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "384px", height: "504px", background: "#E04F16", borderRadius: "16px", }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", paddingTop: "20px", paddingLeft: "20px" }}>
              <img src={Command} alt="" />
              <p className='quote-card-header-txt'>Command+R</p>
            </div>
            <div className='quote-card-info' style={{ width: "300px", height: "286px", marginTop: "135px" }}>
              <div className='quote-card-info-txt'>
                <p className='quote-card-info-header-txt'>Command+R</p>
                <p className='quote-card-info-sub-txt'>“Untitled has saved us thousands of hours of work. We’re able to spin up projects faster.”</p>
                <div className='quote-card-info-link'>
                  <p>Read case study</p>
                  <IconContext.Provider value={{ size: '1rem', color: '#FFFFFF' }}>
                    <FiArrowUpRight />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='quote-slide'>
          <button className="arrow-btn" onClick={scrollLeft}>
            <IconContext.Provider value={{ size: '1rem', color: '#667085' }}>
              <AiOutlineArrowLeft />
            </IconContext.Provider>
          </button>
          <button className="arrow-btn" onClick={scrollRight}>
            <IconContext.Provider value={{ size: '1rem', color: '#667085' }}>
              <AiOutlineArrowRight />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Quote