import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import UserDetailsProvider from "./UserDetailsContext";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";
import { useTransactionsRequest } from "../../services/request-hooks/transactions/useTransactions";
import { categorizeTransactions } from "../../utils/CategorizeTransaction";

function Dashboard() {
  const [transactions, setTransactions] = useState(null);
  const user = {
    name: "Ladejobi Blessing",
    username: "Lab",
    email: "blessingladejobi@gmail.com",
  };
  const { viewDetails, viewWallets } = useAccountOwnersRequest();
  useEffect(() => {
    // viewDetails();
    // viewWallets();
  }, []);
  const { viewTransactions } = useTransactionsRequest();
  useEffect(() => {
    // P2P_TRANSFER, PAYMENT, MERCHANT_PAYMENT
    const transactionsPayload = {
      financialTransactionType: "PAYMENT",
      size: 15,
      page: 0,
    };
    const fetchData = async () => {
      const response = await viewTransactions(transactionsPayload);
      const categorizedTransactions = categorizeTransactions(response);
      setTransactions(categorizedTransactions);
    };
    fetchData();
  }, []);

  return (
    <UserDetailsProvider>
      <div className="w-full flex itesm-start">
        <Sidebar user={user} />
        <div className="w-full col-span-4 h-screen bg-white px-8 overflow-y-auto pb-8">
          <Navbar user={user} />
          <Outlet context={{ user, transactions }} />
        </div>
      </div>
    </UserDetailsProvider>
  );
}

export default Dashboard;
