import { useState } from "react";
import { PostHttp } from "../../https/PostHttp";
import { toast } from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PutHttp } from "../../https/PutHttp";
import { DeleteHttp } from "../../https/DeleteHttp";

export const useUsersRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const viewAll = async (
    payload = {
      size: 100,
      page: 0,
    }
  ) => {
    setIsLoading(true);
    try {
      const response = await GetHttp("/adminusers", payload);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        setIsLoading(false);
        return data.responseBody.contents;
      } else {
        
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const viewOne = async (
    payload = {
      size: 100,
      page: 0,
      id,
    }
  ) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await GetHttp(`/adminuser/${id}`, payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseBody;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const create = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.username) {
        toast.error("Username is required");
      } else if (!payload.emailAddress) {
        toast.error("User email address is required");
      } else if (!payload.roleIdentifier) {
        toast.error("User role ID is required");
      } else if (!payload.password) {
        toast.error("User password is required");
      } else {
        const response = await PostHttp("/adminuser", payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          toast.success(data.responseDescription);
          setIsLoading(false);
          return response;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const updateOne = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID to be updated is required");
      } else if (!payload.username) {
        toast.error("Username is required");
      } else if (!payload.emailAddress) {
        toast.error("User email address is required");
      } else if (!payload.roleIdentifier) {
        toast.error("User role ID is required");
      } else if (!payload.password) {
        toast.error("User password is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await PutHttp(`/adminuser/${id}`, payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          console.log(data.responseBody);
          setIsLoading(false);
        } else {
          
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const deleteOne = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload?.id) {
        toast.error("User ID to be delete is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await DeleteHttp(`/adminuser/${id}`);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseDescription;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const blockUser = async (
    payload = {
      id,
    }
  ) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await GetHttp(`/adminuser/${id}/block`, {
          status: true,
        });
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseDescription;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const unblockUser = async (
    payload = {
      id,
    }
  ) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await GetHttp(`/adminuser/${id}/block`, {
          status: false,
        });
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseDescription;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  return {
    isLoading,
    create,
    updateOne,
    viewAll,
    viewOne,
    deleteOne,
    blockUser,
    unblockUser,
  };
};
