import React, { useState } from "react";
import OverlayProvider from "../../overlay/Overlay/OverlayContext";

function TableColumn({ data }) {
  const [modalName, setModalName] = useState("");
  const [receiptPayload, setReceiptPayload] = useState({
    // type: "Send money",
    // amount: "₦50,000",
    // mobile: "0806 166 1336",
    // status: "Successful",
    // id: "1AB4RYCUHDSD-PM",
    // description: "Groceries",
    // date: "17/01/2023"
  });
  const onCreateReceiptInputChange = (e) => {
    const { name, value } = e.target;
    setReceiptPayload({
      ...receiptPayload,
      [name]: value,
    });
  };

  const createReceipt = (data) => {
    setModalName('create-receipt');
    setReceiptPayload(data)

    // pass the filter function
  };

  const hideOverlay = () => setModalName("");
  const { avatar, name, time, date, isInflow, currency, amount, info, transactionType, recipientNo, transactionStatus, recipientName, transactionId, description } = data;
  return (
    <React.Fragment>
      <OverlayProvider
        // selectedTransaction={"helloWorld"}
        receiptPayload={receiptPayload}
        onCreateReceiptInputChange={onCreateReceiptInputChange}
        modalName={modalName}
        setModalName={setModalName}
        hideOverlay={hideOverlay}
      >
        <div onClick={() => createReceipt(data)} className="flex hover:bg-gray-100 px-3 rounded-lg h-[72px] items-center justify-between">
          <div className="profile_info flex items-center gap-2">
            <div className="avatar relative w-10 h-10 rounded-full shadow bg-gray-300 font-bold flex items-center justify-center text-lg">
              {avatar ? (
                <img
                  src={avatar}
                  alt="user__avatar"
                  className="absolute w-full h-full rounded-full"
                />
              ) : (
                `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
              )}
            </div>
            <div>
              <h5 className="text-[16px] text-gray-600">{name}</h5>
              <p className="flex items-center text-[12px] text-gray-400 gap-2">
                {time}
                <span className="w-1 h-1 rounded-full bg-gray-600"></span>
                {date}
              </p>
            </div>
          </div>
          {info ? (
            <p className="text-sm text-gray-500">
              {info}
            </p>
          ) : (
            <p
              className={`text-sm ${isInflow ? "text-success-500" : "text-error-500"
                }`}
            >
              {currency}
              {amount}
            </p>
          )}
        </div>
      </OverlayProvider>
    </React.Fragment>


  );
}

export default TableColumn;
