import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import { useNavigate } from "react-router-dom";
import Himg from '../../../../assets/Himg.svg';
import Boltshift from '../../../../assets/Boltshift.svg';
import FeatherDev from '../../../../assets/FeatherDev.svg';
import GlobalBank from '../../../../assets/GlobalBank.svg';
import Lightbox from '../../../../assets/Lightbox.svg';
import Spherule from '../../../../assets/Spherule.svg';
import Nietzsche from '../../../../assets/Nietzsche.svg';

function Header() {
    const navigate = useNavigate()
    return (
        <div className='bg-[#f9fafb] mx-auto px-[5px] sm:px-[25px] md:px-[45px] lg:px[100px] pt-[200px]'>
            <div className='flex flex-col md:flex-row gap-[20px] lg:justify-between'>
                <div className='flex flex-col pt-0 md:pt-[80px] gap-[24px]'>
                    <div className='w-[360px] flex flex-row rounded-[16px] font-medium text[14px] items-center bg-[#d9e1f9] gap-2 text-primary-600 pl-[10px] pt-[4px] pb-[4px] pr-[4px] '>
                        <p className='bg-[#FFFFFF] rounded-[16px] px-[10px] py-[2px]'>What's new?</p>
                        <p className=''>Instanly issue virtual card</p>
                        <BsArrowRightShort />
                    </div>
                    <div className=''>
                        <h1 className='font-semibold not-italic text-[43px] leading-[62px] text-[#101828] tracking-tight'>No more business banking headaches</h1>
                    </div>
                    <div className=''>
                        <p className='font-normal not-italic text-[#475467] tracking-tight text-[20px] leading-8'>
                            PayMoor is a next-gen financial technology company in the process of reinventing banking.
                        </p>
                    </div>
                    <div className='flex gap-4 justify-center md:justify-start'>
                        <button type="" onClick={() => navigate('/register')} className="bg-[#3658BB] text-[#FFFFFF] rounded-md px-[28px] py-[16px] cursor-pointer justify-center overflow-hidden border-none h-[60px]">Create account</button>
                        <button type="" className="bg-transparent text-[#344054] px-[28px] py-[16px] flex items-center gap-1 justify-center border-2 border-[#344054] cursor-pointer rounded-md h-[60px]">
                            <IconContext.Provider value={{ size: '2rem', color: '#344054' }}>
                                <AiOutlinePlayCircle />
                            </IconContext.Provider>
                            Demo
                        </button>
                    </div>
                </div>

                <div className='flex items-center justify-center'>
                    <img src={Himg} alt="" />
                </div>
            </div>

            <div className=' pt-20 flex flex-col gap-14 w-full'>
                <div className='flex justify-center items-center'>
                    <p className='font-medium not-italic text-lg leading-6 text-[#475467]'>
                        Join 4,000+ companies already growing
                    </p>
                </div>

                <div className='grid sm:grid grid-rows-3 grid-flow-col gap-4 md:grid md:grid-cols-3 md:grid-flow-row lg:flex sm:gap-4 md:gap-6 items-center justify-center'>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={Boltshift} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>Boltshift</p>
                    </div>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={Lightbox} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>Lightbox</p>
                    </div>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={FeatherDev} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>FeatherDev</p>
                    </div>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={Spherule} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>Spherule</p>
                    </div>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={GlobalBank} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>GlobalBank</p>
                    </div>
                    <div className='flex gap-2 sm:gap-2 md:gap-2 items-center'>
                        <img src={Nietzsche} alt="" />
                        <p className='font-black text-[#101828] leading-8 text-base md:text-xl'>Nietzsche</p>
                    </div>
                    {/* <div className='flex gap-3 sm:gap-1 md:gap-6 flex-col sm:flex-row md:flex-row'>

                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Header