import React from "react";
import { SVGS } from "../../assets";

function PrimaryButton(props) {
  const {
    text,
    isContinueBtn,
    isLoading,
    isActive,
    ...restProps
  } = props;
  return (
    <button
      disabled={!isActive || isLoading}
      {...restProps}
      className={`w-full h-[44px] flex items-center justify-center gap-2 text-[16px] rounded-lg bg-primary-600 ${
        isActive ? "opacity-100" : "opacity-60"
      } text-white font-semibold transition-all duration-500`}
    >
      {isLoading ? (
        <div className="flex items-center gap-2">
          <span className="w-6 h-6 rounded-full border border-primary-white border-t-transparent animate-spin flex"></span>{" "}
          <span>Loading...</span>
        </div>
      ) : (
        <>
          {text}
          {isContinueBtn && <SVGS.ContinueBtn />}
        </>
      )}
    </button>
  );
}

export default PrimaryButton;
