import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { PostHttp } from "../services/https/PostHttp";

export default function RevalidateToken(error) {
  const errorMsg =
    error?.response?.data?.responseBody?.errorDescription ||
    error.response.data.error;

  if (errorMsg.includes("access token is invalid and/or expired")) {
    const payload = {
      identity: {
        identityValue: "string",
        identityType: "MSISDN",
      },
      // credential: {
      //   credentialValue: "string",
      //   credentialType: "PASSWORD",
      // },
      refreshToken: JSON.parse(window.sessionStorage.getItem("token")).rtk,
    };
    const generateNewTokenHandler = async () => {
      const generateNewToken = await PostHttp("/token", payload, true);
      if (generateNewToken.data.responseStatus) {
        // const token = JSON.stringify({
        //   atk: generateNewToken.data.responseBody.accessToken,
        //   rtk: generateNewToken.data.responseBody.refreshToken,
        // });
        //   window.sessionStorage.setItem("token", token);
        //   window.sessionStorage.setItem("user", user);
        //   toast.success(data.data.responseDescription);
        //   setRedirectPath(`/dashboard`);
      }
    };
    generateNewTokenHandler();
  } else {
  }
  toast.error(errorMsg);
}
