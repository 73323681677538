import { useState } from "react";
import { toast } from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";
import { DeleteHttp } from "../../https/DeleteHttp";

export const useAccountOwnersRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const viewWallets = async () => {
    setIsLoading(true);
    try {
      const response = await GetHttp("/accountowner/wallets");
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        setIsLoading(false);
        return data.responseBody.contents;
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const viewDetails = async (payload) => {
    setIsLoading(true);
    try {
      const response = await GetHttp("/accountowners", payload);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        setIsLoading(false);
        return data.responseBody.contents;
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const viewDetailWithId = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload?.id) {
        toast.error("Account owner ID is required");
      } else {
        const { id } = payload;
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let idType = "";
        const isEmail = emailRegex.test(JSON.stringify(id));
        const isMSISDN = id.length > 10 && id.length < 14;
        if (isEmail) {
          idType = "EMAIL";
        } else if (isMSISDN) {
          idType = "MSISDN";
        } else {
          idType = "USERNAME";
        }
        const newPayload = {
          id: id,
          type: idType,
        };
        const response = await GetHttp(`/accountowner/id`, newPayload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseBody;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const blockUser = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID to be blocked is required");
      } else {
        const id = payload.id;
        const type = payload.type;
        const response = await GetHttp(`/accountowner/block?id=${id}&type=${type}`);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseBody;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const unblockUser = async (payload) => {
    console.log(payload)
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("User ID to be unblocked is required");
      } else {
        const id = payload.id;
        const type = payload.type;
        const response = await GetHttp(`/accountowner/unblock?id=${id}&type=${type}`);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseBody;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const createMerchant = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.firstName) {
        toast.error("Firstname is required");
      } else if (!payload.lastName) {
        toast.error("Lastname is required");
      } else if (!payload.emailAddress) {
        toast.error("Email Address is required");
      } else if (!payload.username) {
        toast.error("Username is Required");
      } else if (!payload.business.businessName) {
        toast.error("Business Name is required");
      } else if (!payload.msisdn) {
        toast.error("Phone Number is required");
      } else if (!payload.credential.credentialValue) {
        toast.error("Password is required");
      } else {
        const response = await PostHttp("/accountowner", payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseStatus;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };

  const createAgent = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.firstName) {
        toast.error("Firstname is required");
      } else if (!payload.lastName) {
        toast.error("Lastname is required");
      } else if (!payload.username) {
        toast.error("Username is Required");
      } else if (!payload.emailAddress) {
        toast.error("Email Address is required");
      } else if (!payload.msisdn) {
        toast.error("Phone Number is required");
      } else if (!payload.credential.credentialValue) {
        toast.error("Password is required");
      } else {
        const response = await PostHttp("/accountowner", payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseStatus;
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const deleteAccountOwner = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload?.id) {
        toast.error("User ID to be delete is required");
      } else {
        const id = payload.id;
        const type = payload.type;
        const response = await DeleteHttp(`/accountowner?id=${id}&type=${type}`);
        if (response?.status >= 200 && response?.status <= 204) {
          console.log("here")
          const { data } = response;
          setIsLoading(false);
          return data.responseDescription;
        } else {
          console.log("else")
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };

  return {
    isLoading,
    createMerchant,
    createAgent,
    viewDetails,
    viewDetailWithId,
    viewWallets,
    blockUser,
    unblockUser,
    deleteAccountOwner
  };
};
