import DashboardOutlet from "../pages/dashboard/DashboardOutlet";
import ManageAdminOutlet from "../pages/manage-admin/ManageAdminOutlet";
import ManageCustomerOutlet from "../pages/manage-customer/ManageCustomerOutlet";
import ManageAgentOutlet from "../pages/manage-agent/ManageAgentOutlet";
import ManageMerchantOutlet from "../pages/manage-merchant/ManageMerchantOutlet";
import AdminDetailOutlet from "../pages/manage-admin/AdminDetailOutlet";
import AgentDetailOutlet from "../pages/manage-agent/AgentDetailOutlet";
import MerchantDetailOutlet from "../pages/manage-merchant/MerchantDetailOutlet";
import CustomerDetailOutlet from "../pages/manage-customer/CustomerDetailOutlet";
import ManageRolesOutlet from "../pages/manage-roles/ManageRolesOutlet";

export const ADMIN_PRIVATE_ROUTES = [
  { href: "", element: <DashboardOutlet /> },
  { href: "/manage-admins", element: <ManageAdminOutlet /> },
  { href: "/manage-admins/:userId", element: <AdminDetailOutlet /> },
  { href: "/manage-customers", element: <ManageCustomerOutlet /> },
  { href: "/manage-customers/:userId", element: <CustomerDetailOutlet />},
  { href: "/manage-agents", element: <ManageAgentOutlet /> },
  { href: "/manage-agents/:userId", element: <AgentDetailOutlet />},
  { href: "/manage-merchants", element: <ManageMerchantOutlet /> },
  { href: "/manage-merchants/:userId", element: <MerchantDetailOutlet />},
  {href: "/manage-roles", element: <ManageRolesOutlet />}
];
