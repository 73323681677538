import React, { useState } from "react";
import { SVGS } from "../../assets";

function RightGrid() {
  const [commentID, setCommentID] = useState(0);
  const [comments] = useState([
    {
      message:
        "We’ve been using PayMoor to manage our finances and can’t imagine working without it.",
      user: "Andi Lane",
      position: "Founder, Catalog",
      brandType: "Fashion Design Agency",
    },
    {
      message:
        "We have found PayMoor to be essential for managing our finances and cannot envision a future without it.",
      user: "Andy Lankis",
      position: "Co-Founder, Furnitio",
      brandType: "Furniture & Interior Design",
    },
    {
      message:
        "PayMoor has made managing our finances so much easier and we cannot imagine going back to the way we used to do things.",
      user: "Blessing Ladejobi",
      position: "Founder, Thexagon",
      brandType: "Digital Agency",
    },
  ]);

  const NextHandler = () => {
    const nextId = commentID < comments.length - 1 ? commentID + 1 : 0;
    setCommentID(nextId);
  };
  const PrevHandler = () => {
    const prevId = commentID > 0 ? commentID - 1 : comments.length - 1;
    setCommentID(prevId);
  };
  return (
    <div className="right_featured_image1 bg-primary-50 relative w-full h-full hidden md:flex items-end justify-center overflow-hidden">
      {/* <img
        src={LoginFeaturedImg}
        width={"100%"}
        alt="login__featured__img"
        className="object-contain"
      /> */}

      <div className="review__container overflow-hidden rounded py-6 px-6 bg-[#ffffff4d] backdrop-blur-lg w-full xl:w-[640px] h-[282px] mb-8 mx-auto">
        <h4 className="review__msg text-white font-semibold text-3xl">
          “
          {comments[commentID].message.length > 104
            ? comments[commentID].message.slice(0, 104) + "..."
            : comments[commentID].message}
          ”
        </h4>
        <div className="flex items-center justify-between mt-4">
          <h3 className="text-white text-4xl font-semibold">
            {comments[commentID].user}
          </h3>
          <div className="flex items-center gap-2">
            <SVGS.StarIcon />
            <SVGS.StarIcon />
            <SVGS.StarIcon />
            <SVGS.StarIcon />
            <SVGS.StarIcon />
          </div>
        </div>

        <div className="flex items-center justify-between mt-3">
          <div className="user__details">
            <h5 className="font-semibold text-white">
              {comments[commentID].position}
            </h5>
            <p className=" text-white">{comments[commentID].brandType}</p>
          </div>

          <div className="flex items-center gap-3">
            <button onClick={PrevHandler}>
              <SVGS.CarouselArrowLeft2 />
            </button>
            <button onClick={NextHandler}>
              <SVGS.CarouselArrowRight2 />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightGrid;
