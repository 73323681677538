import React from "react";
import { Icons } from "../../assets/icons";
import { toast } from "react-hot-toast";

function DetailsCard({ label, value }) {
  const copyToClipboard = (value) => {
    window.navigator.clipboard.writeText(value);
    toast.success(`${label} copied`);
  };
  return (
    <button
      onClick={() => copyToClipboard(value)}
      className="w-full h-[72px] text-left rounded-lg bg-[#EFF2FA] py-5 px-6 relative"
    >
      <span className="text-xs text-primary-700 flex items-center gap-x-2 absolute right-5 ">
        copy <Icons.CopyIcon />
      </span>
      <p className="text-xs text-gray-500">{label}</p>
      <h5 className="text-sm text-gray-700 font-semibold">{value}</h5>
    </button>
  );
}

export default DetailsCard;
