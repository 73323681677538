import { useState } from "react";
import { PostHttp } from "../../https/PostHttp";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useLoginRequest = (payload) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigateTo = useNavigate();
  const mutate = async () => {
    setIsLoading(true);
    try {
      const response = await PostHttp("/token", payload, false);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        const { refreshToken, accessToken, roleType } = data.responseBody;
        if (roleType === "SUPER_ADMINISTRATOR") {
          const token = {
            atk: accessToken,
            rtk: refreshToken,
          };
          const user = {
            roleType: roleType,
            name: payload.identity.identityValue,
          };
          window.sessionStorage.setItem("token", JSON.stringify(token));
          window.sessionStorage.setItem("user", JSON.stringify(user));
          setTimeout(() => {
            toast.success(data.responseDescription);
            setIsLoading(false);
            window.location.pathname = "/admin";
            navigateTo(`/admin`);
          }, 2000);
        } else {
          toast.error(
            "You can only login as a super administrator on the web for now."
          );
        }
      } else {
        setIsLoading(false);
        // return response;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  return { isLoading, mutate };
};
