import React from 'react';
import { Icons } from '../../assets/icons';

function DateInput(props) {
    const {
        name,
        label,
        widthBackground,
        errorMsg,
        isValid,
        isnotrequired = true,
        ...restProps
    } = props;
    return (
        <div className='w-full relative'>
            <label
                htmlFor={name}
                className="text-[14.5px] pb-1 block text-gray-700 font-medium"
            >
                {label}
                {isnotrequired ? (
                    ""
                ) : (
                    <span className="text-red-700 font-semibold">*</span>
                )}
            </label>
            <input
                name={name}
                type="date"
                required
                className={`w-full h-[48px] rounded-lg shadow-sm border ${widthBackground ? "border-primary-50 bg-[#EFF2FA] text-gray-900" : "border-gray-[#D0D5DD] bg-white text-gray-500"
                    } focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 outline-none px-3.5 text-[14.5px]`}
                {...restProps}
            />
        </div>
    )
}

export default DateInput