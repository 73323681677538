import React from 'react';
import { IconContext } from 'react-icons/lib';

function DropdownItem({icon, name, subtxt}) {
    return (
        <div className='dropdown-item-body'>
            <IconContext.Provider value={{ size: '2rem', color: '#3658BB' }}>
                {icon}
            </IconContext.Provider>
            <div className='dropdown-item-sub-header'>
                <h4>{name}</h4>
                <p className='dropdown-item-subtxt'>
                    {subtxt}
                </p>
            </div>
        </div>
    )
}

export default DropdownItem