import React from "react";
import TableHeader from "../shared/TableHeader";
import TablePartition from "../shared/TablePartition";

function TransactionTable({ tableData }) {
  return (
    <div className="pl-6 pr-3 border border-gray-300 rounded-xl">
      <TableHeader />
      <div className="w-full h-[45vh] overflow-y-scroll custom__scrollbar pr-6">
        {tableData ? (
          <React.Fragment>
            {tableData?.today?.length > 0 && (
              <TablePartition data={tableData?.today} title="Today" />
            )}
            {tableData?.yesterday?.length > 0 && (
              <TablePartition data={tableData?.yesterday} title="Yesterday" />
            )}
            {tableData?.others?.length > 0 && (
              <TablePartition data={tableData?.others} title="Others" />
            )}
            {tableData?.others?.length === 0 &&
              tableData?.yesterday?.length === 0 &&
              tableData?.today?.length === 0 && (
                <p className="text-sm text-gray-500">No transactions available</p>
              )}
          </React.Fragment>
        ) : (
          <p>No transaction found</p>
        )}
      </div>
    </div>
  );
}

export default TransactionTable;
