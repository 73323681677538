import { useState } from "react";
import { PostHttp } from "../../https/PostHttp";
import { toast } from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PutHttp } from "../../https/PutHttp";
import { DeleteHttp } from "../../https/DeleteHttp";

export const useRolesRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const viewAll = async (
    payload = {
      size: 100,
      page: 0,
    }
  ) => {
    setIsLoading(true);
    try {
      const response = await GetHttp("/roles", payload);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        setIsLoading(false);
        return data.responseBody;
      } else {

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const viewOne = async (
    payload = {
      size: 100,
      page: 0,
      id,
    }
  ) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("Role ID is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await GetHttp(`/role/${id}`, payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          console.log(data.responseBody);
          setIsLoading(false);
        } else {

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const create = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.name) {
        toast.error("Role name is required");
      } else if (!payload.description) {
        toast.error("Role description is required");
      } else if (!payload.roletype) {
        toast.error("Role type is required");
      } else if (
        payload.permissions.length === 0 ||
        typeof payload.permissions !== "object"
      ) {
        toast.error("Role permissions are required");
      } else {
        const response = await PostHttp("/role", {...payload, roleType: payload.roletype});
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          // console.log(data.responseBody);
          setIsLoading(false);
          return data.responseStatus
        } else {

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const updateOne = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("Role ID to be updated is required");
      } else if (!payload.name) {
        toast.error("Role name is required");
      } else if (!payload.description) {
        toast.error("Role description is required");
      } else if (!payload.roleType) {
        toast.error("Role type is required");
      } else if (
        payload.permissions.length === 0 &&
        typeof payload.permissions !== "object"
      ) {
        toast.error("Role permissions are required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await PutHttp(`/role/${id}`, payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          // console.log(data.responseBody);
          setIsLoading(false);
        } else {

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const deleteOne = async (
    payload = {
      id,
    }
  ) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("Role ID to be delete is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await DeleteHttp(`/role/${id}`);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          console.log(data.responseBody);
          setIsLoading(false);
        } else {

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const viewPermissions = async () => {
    setIsLoading(true);
    try {
      const response = await GetHttp("/permissions",);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        setIsLoading(false);
        return data.responseBody;
      } else {
        
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  const updatePermission = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.id) {
        toast.error("Permission ID to be updated is required");
      } else if (!payload.status) {
        toast.error("Permission status is required");
      } else {
        const id = payload.id;
        delete payload.id;
        const response = await PutHttp(
          `/role/${id}/permissions?status=${payload.status}`,
          payload
        );
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          console.log(data.responseBody);
          setIsLoading(false);
        } else {

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };
  return {
    isLoading,
    create,
    updateOne,
    viewAll,
    viewOne,
    deleteOne,
    viewPermissions,
    updatePermission,
  };
};
