import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import TextInput from "../../input/TextInput";
import PasswordInput from "../../input/PasswordInput";
import PrimaryButton from "../../button/PrimaryButton";
import { useAccountOwnersRequest } from "../../../services/request-hooks/account-owner/useAccountOwners";

function Index() {
  const { createMerchant } = useAccountOwnersRequest();
  const {
    hideOverlay,
    setModalName,
    createMerchantPayload,
    onCreateMerchantInputChange,
  } = React.useContext(OverlayContext);
  const [isBtnActive, setIsBtnActive] = useState(false);

  useEffect(() => {
    let isAnyKeyEmpty = false;
    Object.keys(createMerchantPayload).forEach((key) => {
      if (createMerchantPayload[key] === "") isAnyKeyEmpty = true;
    });
    if (isAnyKeyEmpty) {
      setIsBtnActive(false);
    } else {
      setIsBtnActive(true);
    }
  }, [createMerchantPayload]);

  const createMerchantHandler = async () => {
    const {firstName, lastName, dateOfBirth, msisdn, username, emailAddress, password, profession, maritalStatus, gender, accountOwnerType, businessName} = createMerchantPayload
    const newPayload = {
      firstName,
      lastName,
      dateOfBirth,
      msisdn,
      username,
      emailAddress,
      credential: {
        credentialValue: password,
        credentialType: "PASSWORD"
      },
      profession,
      maritalStatus,
      gender,
      accountOwnerType,
      business: {
        businessName: businessName,
        companyType: "BUSINESS"
      }
    }
    const response = await createMerchant(newPayload);
    if (response) {
      setModalName("create-merchant-success");
    }
  };
  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="header flex items-center h-[60px] justify-between">
          <h5 className="text-xl font-semibold">Create Merchant account</h5>
          <button
            onClick={hideOverlay}
            className="bg-gray-50 w-9 h-9 rounded flex items-center justify-center"
          >
            <SVGS.CloseIcon />
          </button>
        </div>
        <hr className="bg-gray-200 mt-2" />
        <div className="form-body mt-6">
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.firstName}
              onChange={onCreateMerchantInputChange}
              name="firstName"
              label={"Merchant first name"}
              placeholder="Enter Firstname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.lastName}
              onChange={onCreateMerchantInputChange}
              name="lastName"
              label={"Merchant last name"}
              placeholder="Enter Lastname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.username}
              onChange={onCreateMerchantInputChange}
              name="username"
              label={"Merchant Username"}
              placeholder="Enter Username"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.businessName}
              onChange={onCreateMerchantInputChange}
              name="businessName"
              label={"Merchant company name"}
              placeholder="Enter Company name"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.emailAddress}
              onChange={onCreateMerchantInputChange}
              name="emailAddress"
              label={"Merchant email address"}
              placeholder="Enter Email Address"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createMerchantPayload.msisdn}
              onChange={onCreateMerchantInputChange}
              name="msisdn"
              label={"Merchant phone no"}
              placeholder="Enter phone no"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <PasswordInput
              value={createMerchantPayload.password}
              onChange={onCreateMerchantInputChange}
              name="password"
              label={"Merchant temporary password"}
              placeholder="Type password"
              widthBackground={true}
            />
            <button className="text-primary-700 mt-1 font-semibold text-sm">
              Generate password
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <PrimaryButton
          onClick={createMerchantHandler}
          isLoading={false}
          isActive={isBtnActive}
          text="Create Merchant account"
        />
      </div>
    </div>
  );
}

export default Index;
