import React from "react";
import { SVGS } from "../../assets";
import FooterLogo from "../../assets/FooterLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { toast } from "react-hot-toast";

const sidebarMenu = [
  {
    href: "",
    Icon: Icons.HomeIcon,
    ActiveIcon: Icons.ActiveHomeIcon,
    text: "Dashboard",
  },
  {
    href: "/manage-admins",
    Icon: Icons.ManageAdminIcon,
    ActiveIcon: Icons.ActiveAdminIcon,
    text: "Manage Admins",
  },
  {
    href: "/manage-agents",
    Icon: Icons.ManageAgentIcon,
    text: "Manage Agents",
  },
  {
    href: "/manage-merchants",
    Icon: Icons.ManageMerchantIcon,
    ActiveIcon: Icons.ActiveMerchantIcon,
    text: "Manage Merchants",
  },
  {
    href: "/manage-customers",
    Icon: Icons.ManageCustomerIcon,
    ActiveIcon: Icons.ActiveCustomerIcon,
    text: "Manage Customers",
  },
  {
    href: "/manage-roles",
    Icon: Icons.ManageRolesIcon,
    ActiveIcon: Icons.ActiveRolesIcon,
    text: "Manage Roles"
  }
];
function Sidebar({ user }) {
  const navigateTo = useNavigate();
  const location = useLocation();
  const logoutHandler = () => {
    window.sessionStorage.clear();
    toast.success("Successfully log out");
    navigateTo("/login?logout-successful");
  };
  return (
    <div className="h-screen sticky top-0 left-0 flex flex-col justify-between bg-primary-700 w-[342px] pt-8 px-6">
      <div>
        <a href="/" className="app-logo flex gap-1 items-center text-center">
          <img src={FooterLogo} alt="" />
          <p className='text-[30px] text-[#FFFFFF] font-bold'>PayMoor</p>
          <div className="flex items-center text-center ml-3">
            <p className="uppercase font-medium leading-6 text-[12px] text-[#FFFFFF]">admin</p>
          </div>
        </a>

        <ul className="sidebar-menu my-10">
          {sidebarMenu.map(({ href, Icon, text, ActiveIcon }, index) => {
            const isHrefMatchPathname =
              location.pathname === `/admin${href}` ||
              (href && location.pathname.includes(`${href}`));
            return (
              <Link
                key={index}
                to={`/admin${href}`}
                className={`h-12 my-3 px-[14px] rounded-lg flex items-center gap-x-[14px] ${isHrefMatchPathname
                  ? "bg-white text-[#101828] font-semibold"
                  : "bg-transparent text-[#D9E1F9] font-semibold hover:bg-primary-800"
                  } text-[15.5px] transition-all duration-500`}
              >
                {isHrefMatchPathname && ActiveIcon && <ActiveIcon color={"#3658BB"} />}
                {isHrefMatchPathname && !ActiveIcon && <Icon color={isHrefMatchPathname ? "#3658BB" : "#D9E1F9"} />}
                {!isHrefMatchPathname && <Icon color={isHrefMatchPathname ? "#3658BB" : "#D9E1F9"} />}
                {text}
              </Link>
            );
          })}
        </ul>
      </div>

      <div className="w-full h-[64px] flex items-start mb-4 border-t border-[#cccccc1d]">
        <div className="w-full h-full flex items-center gap-x-3">
          <Icons.Avatar />
          <div>
            <h5 className="font-medium text-white text-sm">
              {user?.name ?? "N/A"}
            </h5>
            <p className="text-primary-200 text-sm w-44 truncate">
              {user?.email ?? "N/A"}
            </p>
          </div>
        </div>
        <button onClick={logoutHandler} className="mt-4">
          <Icons.SignoutIcon />
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
