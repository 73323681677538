import React, { useState } from "react";
import { SVGS } from "../../assets";

function RadioButton(props) {
  const { typeName, selectedIndex, setSelectedIndex, clickHandler, id } = props;

  const selectHandler = (index) => {
    if (index === 1) {
      clickHandler("AGENT");
    } else if (index === 2) {
      clickHandler("BUSINESS");
    } else if (index === 3) {
      clickHandler("CUSTOMER");
    }
    setSelectedIndex(index);
  };
  return (
    <button
      onClick={() => selectHandler(id)}
      className={`w-full h-[60px] rounded-xl border ${
        selectedIndex === id ? "border-primary-700" : "border-gray-200"
      } flex items-center justify-between px-2 my-2 bg-white relative`}
    >
      <div className="flex items-center gap-1">
        {typeName === "Personal" && <SVGS.PersonalIcon />}
        {typeName === "Agent" && <SVGS.AgentIcon />}
        {typeName === "Merchant" && <SVGS.MerchantIcon />}
        {typeName}
      </div>

      {selectedIndex === id ? <SVGS.SelectedRadio /> : ""}
    </button>
  );
}

export default RadioButton;
