import React from "react";
import ToggleSwitchAccountOwn from "../../components/toggle-switch/ToggleSwitchAccountOwn";
import { Link } from "react-router-dom";

function TableRow({ agent }) {
  return (
    <div className="h-[77px] flex items-center justify-between w-full border-b border-gray-200">
      <Link to={`${agent?.msisdn}`} className="flex items-center">
        {agent?.profileImg ? (
          <img
            src={agent?.profileImg}
            className="w-9 h-9 rounded-full border border-gray-200"
            alt=""
          />
        ) : (
          <span className="w-10 h-10 rounded-full border border-gray-300 uppercase font-semibold text-center flex items-center justify-center">
            {agent?.firstName[0]}
            {agent?.lastName[0]}
          </span>
        )}
        <p className="text-sm mx-4 text-gray-600 font-medium capitalize">
          {agent?.firstName} {agent?.lastName}
        </p>
      </Link>
      <div className="flex items-center gap-x-2">
        <p className="text-[15px] text-gray-400">Block user</p>
        <ToggleSwitchAccountOwn
          id={agent?.msisdn}
          status={agent?.status === "ACTIVE" ? false : true}
        />
      </div>
    </div>
  );
}

export default TableRow;
