import React from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import SuccessIcon from "../../../assets/create-agent-success.svg";
import PrimaryButton from "../../button/PrimaryButton";

function CreateAgentSuccess() {
  const { hideOverlay, createAgentPayload } = React.useContext(OverlayContext);

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="header flex items-center h-[60px] justify-between">
          <h5 className="text-xl font-semibold">Agent account created!</h5>
          <button
            onClick={hideOverlay}
            className="bg-gray-50 w-9 h-9 rounded flex items-center justify-center"
          >
            <SVGS.CloseIcon />
          </button>
        </div>
        <hr className="bg-gray-200 mt-2" />
      </div>

      <div className="flex flex-col items-center">
        <img src={SuccessIcon} alt="" />
        <h5 className="text-lg font-semibold mt-4 mb-1">
          New Agent account created!
        </h5>
        <p className="text-[13px] leading-4 text-gray-500 text-center px-10">
          You have successfully created an agent account for{" "}
          {createAgentPayload.email}.
        </p>
      </div>
      <div className="mb-4">
        <PrimaryButton
          onClick={hideOverlay}
          isLoading={false}
          isActive={true}
          text="Done"
        />
      </div>
    </div>
  );
}

export default CreateAgentSuccess;
