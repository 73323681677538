import React, { useState, usestatus } from "react";
import "./toggle-switch.module.css";
import { toast } from "react-hot-toast";
import { useUsersRequest } from "../../services/request-hooks/users/useUsers";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";

const ToggleSwitch = ({ id, status }) => {
  const [isBlocked, setIsBlocked] = useState(status);
  const { blockUser, unblockUser } = useUsersRequest();
  const toggle = async () => {
    const payload = {
      id: id,
    };
    if (isBlocked) {
      const response = await unblockUser(payload);
      if (response.includes("success")) {
        toast.success(`User unblocked successfully`);
        setIsBlocked(false);
      } else {
        toast.error("Failed");
      }
    } else {
      const response = await blockUser(payload);
      if (response.includes("success")) {
        toast.success(`User blocked successfully`);
        setIsBlocked(true);
      } else {
        toast.error("Failed");
      }
    }
  };
  return (
    <button
      onClick={toggle}
      className={`w-[40px] ${isBlocked ? "bg-[#98A2B3]" : "bg-[#cad5ea]"
        } h-[24px] transition-all duration-300 rounded-full relative`}
    >
      <input
        type="checkbox"
        className="toggle-switch absolute block w-0 h-0 opacity-0 z-0"
      />
      <label
        htmlFor="toggle-switch"
        className={`toggle-switch-label bg-white h-3.5 w-3.5 rounded-full flex transition-all duration-500 ${isBlocked ? "ml-[22px]" : "ml-1"
          } mb-[0.5px]`}
      >
        <span className="sr-only">Toggle Switch</span>
      </label>
    </button>
  );
};

export default ToggleSwitch;
