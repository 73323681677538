import React from "react";
import Hr from "./Hr";
import TableColumn from "./TableColumn";

function TablePartition({ data, title }) {
  return (
    <div className="w-full h-auto">
      <Hr title={title}/>
      <div className="flex flex-col">
        {data?.map((prop, index) => (
          <TableColumn key={index} data={prop} />
        ))}
      </div>
    </div>
  );
}

export default TablePartition;
