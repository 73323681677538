import React, { useEffect, useState } from "react";
import { Icons } from "../../../assets/icons";
import OverlayProvider, {
  OverlayContext,
} from "../../overlay/Overlay/OverlayContext";

function TableHeader({}) {
  const { setModalName } = React.useContext(OverlayContext);
  const createFilter = () => {
    setModalName("create-filter");
  };

  return (
    <div className="w-full h-[81.5px] flex items-center justify-between">
      <h4 className="font-medium text-lg">Recent Transation</h4>
      <button
        onClick={createFilter}
        className="bg-[#EFF2FA] text-primary-700 px-7 min-w-[170px] h-16 rounded-xl flex items-center justify-between font-medium ..."
      >
        Filter by
        <Icons.FilterIcon />
      </button>
    </div>
  );
}

export default TableHeader;
