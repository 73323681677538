import React from "react";
import CreateAdmin from "../CreateAdmin";
import CreateAgent from "../CreateAgent";
import CreateMerchant from "../CreateMerchant";
import { OverlayContext } from "./OverlayContext";
import CreateAdminSuccess from "../CreateAdmin/CreateAdminSuccess";
import CreateAgentSuccess from "../CreateAgent/CreateAgentSuccess";
import CreateMerchantSuccess from "../CreateMerchant/CreateMerchantSuccess";
import CreateFilter from "../CreateFilter/index";
import CreateReceipt from "../CreateReceipt/index";
import CreateRoles from "../CreateRoles/index";

function Overlay() {
  const { modalName, hideOverlay } = React.useContext(OverlayContext);
  const closeOnOverlayClick = (e) => {
    const targetClass = e.target.classList;
    if (targetClass.contains("overlay")) {
      hideOverlay();
    }
  };
  return (
    <>
      {modalName && (
        <div
          onClick={closeOnOverlayClick}
          className="overlay w-full h-screen bg-[#344054a4] fixed top-0 left-0 z-20 backdrop-blur flex justify-end"
        >
          <div className="w-[400px] h-screen overflow-y-auto bg-white shadow-lg p-6">
            {modalName === "create-admin" && <CreateAdmin />}
            {modalName === "create-admin-success" && <CreateAdminSuccess />}
            {modalName === "create-agent" && <CreateAgent />}
            {modalName === "create-agent-success" && <CreateAgentSuccess />}
            {modalName === "create-merchant" && <CreateMerchant />}
            {modalName === "create-merchant-success" && (
              <CreateMerchantSuccess />
            )}
            {modalName === "create-filter" && <CreateFilter />}
            {modalName === "create-receipt" && <CreateReceipt />}
            {modalName === "create-roles" && <CreateRoles />}
          </div>
        </div>
      )}
    </>
  );
}

export default Overlay;
