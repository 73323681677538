import React, { useEffect, useState } from "react";
import { SVGS } from "../../assets";
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import { Link, useParams } from "react-router-dom";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";
import { categorizeTransactions } from "../../utils/CategorizeTransaction";
import { useTransactionsRequest } from "../../services/request-hooks/transactions/useTransactions";
import { toast } from "react-hot-toast";
import CustomerAccountTab from "./CustomerAccountTab";
import CustomerSecurityTab from "./CustomerSecurityTab";
import CustomerTransactionTab from "./CustomerTransactionTab";

const tabs = [
  { name: "account", text: "Account Details" },
  { name: "transaction", text: "Transaction History" },
  { name: "security", text: "Security" },
];

function CustomerDetailOutlet() {
  const { userId } = useParams();
  const [userData, setUserData] = useState({
    profile: {},
    account: [],
  });
  const [transactions, setTransactions] = useState([]);
  const { viewDetailWithId } = useAccountOwnersRequest();
  useEffect(() => {
    const viewOneHandler = async () => {
      const payload = { id: userId };
      const response = await viewDetailWithId(payload);
      setUserData({
        ...userData,
        profile: response,
        account: [
          { label: "User ID", value: response?.id },
          // { label: "Email address", value: response?.emailAddress },
          { label: "Username", value: response?.username },
          { label: "Phone no", value: response?.msisdn },
        ],
      });
    };
    viewOneHandler();
  }, []);

  const { viewTransactions } = useTransactionsRequest();
  useEffect(() => {
    if (userData?.profile?.username) {
      const payload = {
        senderIdentity: {
          identityValue: userData?.profile?.username,
          identityType: "USERNAME",
        },
        receiverIdentity: {
          identityValue: userData?.profile?.username,
          identityType: "USERNAME",
        },
        financialTransactionType: "P2P_TRANSFER",
        page: 0,
        size: 100,
      };
      const fetchData = async () => {
        const { response, message } = await viewTransactions(payload);
        if (response?.data?.status < 200 || response?.data?.status > 204) {
          toast.error(message);
        } else {
          const categorizedTransactions = categorizeTransactions(response);
          setTransactions(categorizedTransactions);
        }
      };
      fetchData();
    }
  }, [userData]);

  const [activeTab, setActiveTab] = useState("account");

  const openTab = (name) => {
    setActiveTab(name);
  };
  return (
    <React.Fragment>
      <OverlayProvider>
        <div className="mt-14">
          <div className="title font-medium flex items-center text-xl">
            <span>
              <SVGS.HomeIcon />
            </span>{" "}
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <Link
              to={"/admin/manage-customers"}
              className="text-sm font-medium text-primary-700"
            >
              Manage customers
            </Link>
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <span className="text-sm font-medium text-primary-700">
              {userData?.profile?.firstName}
            </span>
          </div>
          <div className="w-full mt-10">
            <div className="tab-container flex items-center">
              {tabs.map(({ name, text }, index) => (
                <button
                  key={index}
                  onClick={() => openTab(name)}
                  className={`tab h-11 px-5 text-sm font-medium flex items-center justify-center transition-all duration-500 ${activeTab === name
                      ? "bg-primary-50 text-primary-700 border-b-2 border-primary-700"
                      : "bg-white text-gray-500 border-b-2 border-gray-100"
                    }`}
                >
                  {text}
                </button>
              ))}
            </div>
            {activeTab === "account" && (
              <CustomerAccountTab userData={userData} />
            )}
            {activeTab === "transaction" && (
              <CustomerTransactionTab transactions={transactions} />
            )}
            {activeTab === "security" && (
              <CustomerSecurityTab userData={userData} />
            )}
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>
  );
}

export default CustomerDetailOutlet;
