import React, { useEffect, useState } from "react";
import { Icons } from "../../assets/icons";
import { SVGS } from "../../assets";
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";
import TableRow from "./TableRow";
import { useUsersRequest } from "../../services/request-hooks/users/useUsers";
import { useRolesRequest } from "../../services/request-hooks/roles/useRoles";
// import { useOutletContext } from "react-router-dom";

function ManageAdminOutlet() {
  const [admins, setAdmins] = useState([]);
  const [adminRolesSelectOptions, setAdminRolesSelectOptions] = useState([]);
  // const { user } = useOutletContext();
  const { viewAll } = useUsersRequest();
  const { viewAll: _viewAllRoles } = useRolesRequest();
  const viewAllRoles = async () => {
    const _roles = await _viewAllRoles();
    let _superAdminRoles = [];
    let _superAgentRoles = [];
    let _superMerchantRoles = [];
    _roles.contents.forEach(({ id, name, roleType }) => {
      if (roleType === "SUPER_ADMINISTRATOR") {
        _superAdminRoles.push({
          value: id,
          label: name,
        });
      } else if (roleType === "AGENT") {
        _superAgentRoles.push({
          value: id,
          label: name,
        });
      } else if (roleType === "MERCHANT") {
        _superMerchantRoles.push({
          value: id,
          label: name,
        });
      }
    });
    setAdminRolesSelectOptions(_superAdminRoles);
  };
  useEffect(() => {
    viewAllRoles();
  }, []);
  const [adminData, setAdminData] = useState(null);
  const [modalName, setModalName] = useState("");
  const [adminPayload, setAdminPayload] = useState({});
  const [createAdminPayload, setCreateAdminPayload] = useState({
    firstName: "",
    lastName: "",
    username: "",
    emailAddress: "",
    roleIdentifier: "",
    password: "ABc123456!",
  });
  async function fetchUsers() {
    let allUsers = await viewAll();
    const onlySuperAdmin = [];
    allUsers.forEach((admin) => {
      if (admin.roleType === "SUPER_ADMINISTRATOR") {
        onlySuperAdmin.push({
          id: admin.id,
          firstName: admin.firstName,
          lastName: admin.lastName,
          username: admin.username,
          roleId: admin.roleId,
          profileImg: "",
          status: admin.status,
        });
      }
    });
    setAdmins(onlySuperAdmin);
    setAdminData(onlySuperAdmin);
  }
  useEffect(() => {
    fetchUsers();
  }, []);

  const onCreateAdminInputChange = (e) => {
    const { name, value } = e.target;
    setCreateAdminPayload({
      ...createAdminPayload,
      [name]: value,
    });
  };
  const hideOverlay = () => setModalName("");
  const showCreateAdmin = () => setModalName("create-admin");
  const searchAdmin = (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const filteredData = admins.filter((admin) =>
        admin.username.toLowerCase().includes(value.toLowerCase())
      );
      setAdminData(filteredData);
    } else {
      setAdminData(admins);
    }
  };
  return (
    <React.Fragment>
      <OverlayProvider
        fetchUsers={fetchUsers}
        adminPayload={adminPayload}
        setAdminPayload={setAdminPayload}
        adminRolesSelectOptions={adminRolesSelectOptions}
        createAdminPayload={createAdminPayload}
        onCreateAdminInputChange={onCreateAdminInputChange}
        setCreateAdminPayload={setCreateAdminPayload}
        modalName={modalName}
        setModalName={setModalName}
        hideOverlay={hideOverlay}
      >
        <div className="mt-14">
          <div className="title font-medium flex items-center text-xl">
            <span>
              <SVGS.HomeIcon />
            </span>{" "}
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <span className="text-sm font-medium text-primary-700">
              Manage Admins
            </span>
          </div>
          <div className="w-full mt-10">
            <div className="header flex items-center justify-between">
              <h5 className="text-lg font-medium">Admins</h5>
              <div className="flex items-center">
                <div className="w-[323px] mx-5 h-11 relative">
                  <span className="absolute flex w-[18px] top-[12px] left-[12px]">
                    <SVGS.SearchIcon />
                  </span>
                  <input
                    onChange={searchAdmin}
                    type="text"
                    placeholder="Search"
                    className="w-full h-full leading-[48px] text-[16px] transition-all duration-500 pl-8 bg-gray-50 border border-gray-300 outline-none focus:border-primary-300 rounded-lg"
                  />
                </div>
                <button
                  onClick={showCreateAdmin}
                  className="w-[125px] bg-primary-700 rounded-lg h-11 text-white text-sm flex items-center justify-center"
                >
                  Add Admin
                  <span>
                    <SVGS.PlusIcon />
                  </span>
                </button>
              </div>
            </div>
            <div className="user-table-container w-full h-[60vh] mt-6 px-7 py-2.5 bg-gray-50">
              {adminData?.map((admin) => (
                <TableRow key={admin.id} admin={admin} />
              ))}
            </div>
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>
  );
}

export default ManageAdminOutlet;
