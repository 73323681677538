import React, { useState } from "react";
import { SVGS } from "../../assets";

const PasswordInput = (props) => {
  const {
    name,
    label,
    errorMsg,
    widthBackground,
    isValid,
    isnotrequired = true,
    ...restProps
  } = props;
  const [inputType, setInputType] = useState("password");

  const toggleInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div className="w-full relative">
      <label
        htmlFor={name}
        className="text-[14.5px] pb-1 block text-gray-700 font-medium"
      >
        {label}
        {isnotrequired ? (
          ""
        ) : (
          <span className="text-red-700 font-semibold">*</span>
        )}
      </label>
      <input
        name={name}
        type={inputType}
        required
        className={`w-full h-[48px] rounded-lg border ${
          widthBackground
            ? "border-primary-50 bg-[#EFF2FA] text-gray-900"
            : "border-gray-[#D0D5DD] bg-white text-gray-500"
        } shadow-sm focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 text-gray-500 outline-none px-3.5 text-[14.5px]`}
        {...restProps}
      />
      <button
        type="button"
        onClick={toggleInputType}
        className="absolute top-9 right-3 z-20"
      >
        {inputType === "password" ? <SVGS.EyeOn /> : <SVGS.EyeOff />}
      </button>
      {isValid && (
        <span className="absolute right-2 top-9 pt-1">
          <SVGS.CheckedCircle />
        </span>
      )}
      <p
        className={`error__msg text-sm text-gray-500 ${
          errorMsg ? "flex" : "hidden"
        }`}
      >
        {errorMsg}
      </p>
    </div>
  );
};

export default React.memo(PasswordInput);
