import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
import PasswordInput from "../../components/input/PasswordInput";
import TextInput from "../../components/input/TextInput";
import { useLoginRequest } from "../../services/request-hooks/authorization/useLoginRequest";
// import useLoginHandler from "../../services/features/useLoginHandler";

function FormGroupLogin() {
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(true);
  const [payload, setPayload] = useState({
    username: "webadmin",
    password: "ABc123456!",
  });
  const { isLoading, mutate: loginHandler } = useLoginRequest({
    identity: {
      identityValue: payload.username,
      identityType: "USERNAME",
    },
    credential: {
      credentialValue: payload.password,
      credentialType: "PASSWORD",
    },
  });

  useEffect(() => {
    let _isAnyFieldEmpty = false;
    Object.keys(payload).forEach((key) => {
      if (payload[key] === "") {
        _isAnyFieldEmpty = true;
      }
    });
    setIsAnyFieldEmpty(_isAnyFieldEmpty);
  }, [payload]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };
  return (
    <div className="w-[360px]">
      <h4 className="text-3xl font-semibold text-gray-900">Welcome back</h4>
      <p className="text-[16px] text-gray-600 mt-1">
        Welcome back! Please enter your details.
      </p>

      <div className="form__group mt-10">
        <div className="my-5">
          <TextInput
            onChange={onInputChange}
            value={payload.username}
            isnotrequired={true}
            name="username"
            label="Username"
            placeholder="Enter your username"
          />
        </div>
        <div className="my-5">
          <PasswordInput
            onChange={onInputChange}
            value={payload.password}
            isnotrequired={true}
            name="password"
            label="Password"
            placeholder="••••••••"
          />
        </div>
        <div className="my-5 flex items-center justify-between">
          <label
            htmlFor="stay_signed_in"
            className="flex items-center gap-1 cursor-pointer"
          >
            <input type="checkbox" id="stay_signed_in" />
            <span className="text-sm text-gray-600">Stay signed in</span>
          </label>
          {/* <Link
            to="/forget-password"
            className="font-semibold text-sm text-primary-700"
          >
            Forgot password
          </Link> */}
        </div>
        <div className="my-5">
          <PrimaryButton
            isLoading={isLoading}
            isActive={!isAnyFieldEmpty}
            onClick={loginHandler}
            text="Sign in"
          />
        </div>
      </div>
      {/* <div className="my-5 flex items-center justify-center gap-1">
        <p className="text-gray-700 text-sm">Don’t have an account??</p>
        <Link to="/register" className="font-semibold text-primary-700 text-sm">
          Sign up
        </Link>
      </div> */}
    </div>
  );
}

export default FormGroupLogin;
