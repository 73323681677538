import React from "react";

const UserDetailsContext = React.createContext({
  name: "Ladejobi Blessing",
  username: "Lab",
  email: "blessingladejobi@gmail.com",
});
function UserDetailsProvider(props) {
  const { children, ...restProps } = props;
  return (
    <UserDetailsContext.Provider value={{ restProps }}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export default UserDetailsProvider;
