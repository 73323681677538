import React from "react";
import RegisterFeaturedImg from "../../assets/login-featured-img.svg"

function RightGrid2() {
  return (
    <div className="right_featured_image2_ bg-primary-50 w-full h-full hidden md:flex items-center justify-center overflow-hidden">
      <img src={RegisterFeaturedImg} alt="register__featured__img" className="-mr-56 mt-24 min-w-[60%]"/>
    </div>
  );
}

export default RightGrid2;
