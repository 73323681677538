import React from "react";
import Data from "../../components/table/transactionTable/data.json";
import TransactionTable from "../../components/table/transactionTable/TransactionTable";

function AdminTransactionTab({ transactions }) {
  return (
    <div className="user-table-container w-full h-[60vh] mt-2 px-7 py-3 bg-[#FCFCFD]">
      <TransactionTable tableData={transactions} />
    </div>
  );
}

export default AdminTransactionTab;
