import React, { useEffect, useState } from "react";
import './Footer.css';
import FooterLogo from '../../../../assets/FooterLogo.svg';
import Twitter from '../../../../assets/Twitter.svg';
import Facebook from '../../../../assets/Facebook.svg';
import LinkedIn from '../../../../assets//LinkedIn.svg';
import GitHub from '../../../../assets/GitHub.svg';
import AngelList from '../../../../assets/AngelList.svg';
import Dribble from '../../../../assets/Dribble.svg';

function Footer() {
  const [thisYear, setThisYear] = useState("");

  useEffect(() => {
    const date = new Date();
    setThisYear(date.getFullYear());
  }, []);

  return (
    <div className='bg-[#1d2939] mx-auto px-[5px] sm:px-[25px] md:px-[45px] lg:px[100px] pt-[200px]'>
        <div className='footer-header'>
          <div className='footer-header-title'>
            <h2 className="font-semibold not-italic text-[34px] text-[#FFFFFF] leading-[62px] tracking-tight">Start your 30-day free trial</h2>
            <p className='footer-header-title-txt'>Join over 4,000+ startups already growing with PayMoor.</p>
          </div>
          <div className='footer-crud'>
            <div className='footer-btn'>
              <button type="" className='cblu-bck'>Learn more</button>
              <button style={{ color: "#FFFFFF" }} type="" className='cbig-wht'>Get started</button>
            </div>
          </div>
        </div>

        <hr />

        <div className='footer-body'>
          <div className='footer-body-section'>
            <div className='footer-body-logo-section'>
              <div className='footer-body-logo'>
                <img src={FooterLogo} alt="" />
                <p className='text-[30px] text-[#FFFFFF] font-bold'>PayMoor</p>
              </div>
              <p className='footer-body-logo-section-txt'>Have amazing transactions and experiences that create more happiness in the world.</p>
            </div>

            <div className='footer-info-table-section'>
              <div className='footer-info-table'>
                <div className='footer-info-table-header'>
                  <p className='footer-info-table-header-txt'>Product</p>
                </div>
                <div className='footer-info-table-body'>
                  <p className='footer-info-table-body-txt'>Overview</p>
                  <p className='footer-info-table-body-txt'>Features</p>
                  <p className='footer-info-table-body-txt'>Solutions</p>
                  <p className='footer-info-table-body-txt'>Tutorials</p>
                  <p className='footer-info-table-body-txt'>Pricing</p>
                  <p className='footer-info-table-body-txt'>Releases</p>
                </div>
              </div>

              <div className='footer-info-table'>
                <div className='footer-info-table-header'>
                  <p className='footer-info-table-header-txt'>Company</p>
                </div>
                <div className='footer-info-table-body'>
                  <p className='footer-info-table-body-txt'>About us</p>
                  <p className='footer-info-table-body-txt'>Careers</p>
                  <p className='footer-info-table-body-txt'>Press</p>
                  <p className='footer-info-table-body-txt'>News</p>
                  <p className='footer-info-table-body-txt'>Media kit</p>
                  <p className='footer-info-table-body-txt'>Contact</p>
                </div>
              </div>

              <div className='footer-info-table'>
                <div className='footer-info-table-header'>
                  <p className='footer-info-table-header-txt'>Resources</p>
                </div>
                <div className='footer-info-table-body'>
                  <p className='footer-info-table-body-txt'>Blog</p>
                  <p className='footer-info-table-body-txt'>Newsletter</p>
                  <p className='footer-info-table-body-txt'>Events</p>
                  <p className='footer-info-table-body-txt'>Help centre</p>
                  <p className='footer-info-table-body-txt'>Tutorials</p>
                  <p className='footer-info-table-body-txt'>Support</p>
                </div>
              </div>

              <div className='footer-info-table'>
                <div className='footer-info-table-header'>
                  <p className='footer-info-table-header-txt'>Social</p>
                </div>
                <div className='footer-info-table-body'>
                  <p className='footer-info-table-body-txt'>Twitter</p>
                  <p className='footer-info-table-body-txt'>LinkedIn</p>
                  <p className='footer-info-table-body-txt'>Facebook</p>
                  <p className='footer-info-table-body-txt'>GitHub</p>
                  <p className='footer-info-table-body-txt'>AngelList</p>
                  <p className='footer-info-table-body-txt'>Dribble</p>
                </div>
              </div>

              <div className='footer-info-table'>
                <div className='footer-info-table-header'>
                  <p className='footer-info-table-header-txt'>Legal</p>
                </div>
                <div className='footer-info-table-body'>
                  <p className='footer-info-table-body-txt'>Terms</p>
                  <p className='footer-info-table-body-txt'>Privacy</p>
                  <p className='footer-info-table-body-txt'>Cookies</p>
                  <p className='footer-info-table-body-txt'>Licenses</p>
                  <p className='footer-info-table-body-txt'>Settings</p>
                  <p className='footer-info-table-body-txt'>Contact</p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <hr />

        <div className='footer-footer'>
          <div className='footer-footer-txt'>
            <p className='footer-footer-subtxt' >© {thisYear} PayMoor. All rights reserved.</p>
          </div>
          <div className='footer-footer-img'>
            <img src={Twitter} alt="" />
            <img src={LinkedIn} alt="" />
            <img src={Facebook} alt="" />
            <img src={GitHub} alt="" />
            <img src={AngelList} alt="" />
            <img src={Dribble} alt="" />
          </div>
        </div>
    </div>
  )
}

export default Footer