import React from 'react'
import RightGrid from '../../components/onboarding/RightGrid'
import LeftGridRegister from './LeftGrid.register';

function Register() {
  return (
    <div className='register__page__container'>
        <div className="w-full h-screen grid grid-cols-1 md:grid-cols-2">
            <LeftGridRegister />
            <RightGrid />
        </div>
    </div>
  )
}

export default Register