import React from 'react';
import './Newsletter.css';
import Epicurious from '../../../../assets/Epicurious.svg';
import CloudWatch from '../../../../assets/CloudWatch.svg';
import AcmeCorp from '../../../../assets/AcmeCorp.svg';
import Polymath from '../../../..//assets/Polymath.svg';
import AltShift from '../../../../assets/Alt+Shift.svg';
import Luminous from '../../../../assets/Luminous.svg';
import FocalPoint from '../../../../assets/FocalPoint.svg';
import Galileo from '../../../../assets/Galileo.svg';
import Nietzsche from '../../../../assets/Nietzsche.svg';


function Newsletter() {
    return (
        <div>
            <div className='newsletter-container'>
                <div className='newsletter-header'>
                    <div className='newsletter-header-txt'>
                        <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>Join over 4,000+ startups growing with PayMoor</h2>
                        <p>Start your 30-day free trial today.</p>
                        <div className='newsletter-btn'>
                            <button type="" className='cblu-bck'>Learn more</button>
                            <button type="" className='cbig-wht'>Get started</button>
                        </div>
                    </div>
                    
                    <div className='newsletter-header-img'>
                        <div className='newsletter-header-img-section'>
                            <div className='newsletter-header-img-txt'>
                                <img src={Epicurious} alt="" />
                                <p className='newsletter-header-img-subtxt'>Epicurious</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={CloudWatch} alt="" />
                                <p className='newsletter-header-img-subtxt'>CloudWatch</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={AcmeCorp} alt="" />
                                <p className='newsletter-header-img-subtxt'>Acme Corp</p>
                            </div>
                        </div>

                        <div className='newsletter-header-img-section'>
                            <div className='newsletter-header-img-txt'>
                                <img src={Polymath} alt="" />
                                <p className='newsletter-header-img-subtxt'>Polymath</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={AltShift} alt="" />
                                <p className='newsletter-header-img-subtxt'>Alt+Shift</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={Luminous} alt="" />
                                <p className='newsletter-header-img-subtxt'>Luminous</p>
                            </div>
                        </div>

                        <div className='newsletter-header-img-section'>
                            <div className='newsletter-header-img-txt'>
                                <img src={FocalPoint} alt="" />
                                <p className='newsletter-header-img-subtxt'>FocalPoint</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={Galileo} alt="" />
                                <p className='newsletter-header-img-subtxt'>Galileo</p>
                            </div>
                            <div className='newsletter-header-img-txt'>
                                <img src={Nietzsche} alt="" />
                                <p className='newsletter-header-img-subtxt'>Nietzsche</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='newsletter-footer'>
                    <div className='newsletter-footer-title'>
                        <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>Sign up for our newsletter</h2>
                        <p className='newsletter-footer-title-txt'>Be the first to know about releases and industry news and insights.</p>
                    </div> 
                    <div className='newsletter-footer-crud'>
                        <div className='newsletter-input'>
                            <input className='newsletter-input-value' placeholder='Enter your email'/>
                            <button type="" className='cblu-bck'>Subscribe</button>
                        </div>
                        <p className='newsletter-footer-crud-txt'>We care about your data in our privacy policy.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter