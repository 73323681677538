import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import TextInput from "../../input/TextInput";
import PrimaryButton from "../../button/PrimaryButton";
import DateInput from "../../input/DateInput";
import SelectInput from "../../select/SelectInput";

function Index() {
  const {
    hideOverlay,
    onResetFilter,
    filterPayload,
    onFilterInputChange,
    filterTransactionHandler,
  } = React.useContext(OverlayContext);
  const [isBtnActive, setIsBtnActive] = useState(false);

  // Validate if atleast one field is filled.
  useEffect(() => {
    let isAnyKeyFilled = false;
    Object.keys(filterPayload).forEach((key) => {
      if (filterPayload[key] !== "") isAnyKeyFilled = true;
    });
    if (isAnyKeyFilled) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [filterPayload]);

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="header flex items-center h-[60px] justify-between">
          <h5 className="text-xl font-semibold">Filter By</h5>
          <button
            onClick={hideOverlay}
            className="bg-transparent w-9 h-9 rounded flex items-center justify-center"
          >
            <SVGS.CloseIcon />
          </button>
        </div>
        <hr className="bg-gray-200 mt-2" />
        <div className="form-body mt-6">
          <div className="mb-4">
            <SelectInput
              options={[
                { value: "MSISDN", label: "MOBILE NUMBER" },
                { value: "USERNAME", label: "USERNAME" },
                { value: "EMAIL", label: "EMAIL" },
              ]}
              name="identityType"
              value={filterPayload.identityType}
              onChange={onFilterInputChange}
              label={"Identity Type"}
              placeholder="Select Identity Type"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              disabled={!filterPayload.identityType}
              name="identity"
              type="identity"
              value={filterPayload.identity}
              onChange={onFilterInputChange}
              label={"Identity"}
              placeholder="Enter user identity"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <SelectInput
              options={[
                { value: "P2P_TRANSFER", label: "P2P TRANSFER" },
                { value: "PAYMENT", label: "PAYMENT" },
                { value: "MERCHANT_PAYMENT", label: "MERCHANT PAYMENT" },
              ]}
              name="transactionType"
              value={filterPayload.transactionType}
              onChange={onFilterInputChange}
              label={"Transaction Type"}
              placeholder="Select transaction type"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <DateInput
              name="fromDate"
              value={filterPayload.fromDate}
              onChange={onFilterInputChange}
              label={"From date"}
              placeholder=""
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <DateInput
              name="toDate"
              value={filterPayload.toDate}
              onChange={onFilterInputChange}
              label={"To date"}
              placeholder=""
              widthBackground={true}
            />
          </div>
        </div>

        <div className="flex items-center justify-between gap-16 mb-4 mt-8">
          <button
            onClick={onResetFilter}
            className="bg-transparent border-gray-500 text-gray-500 border-2 px-4 py-2 rounded-md font-semibold"
          >
            Reset
          </button>
          <PrimaryButton
            onClick={filterTransactionHandler}
            isLoading={false}
            isActive={isBtnActive}
            text="Apply filter(s)"
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
