import AxiosInstance from "../interceptor/useResponseInterceptor";
import toast from "react-hot-toast";
import { CONFIG } from "../../config";
import { AccessToken } from "../request-hooks/accessToken/AccessToken";

export const DeleteHttp = async (
  path,
  payload = null,
  isRoutePrivate = true, //private routes requires personal access token to run/ public requires web admin token
  contentType = "application/json"
) => {
  try {
    if (!window.navigator.onLine) {
      toast("You don't have internet connection at the moment.");
    } else {
      const token = JSON.parse(sessionStorage.getItem("token"));

      let header;
      if (token?.atk && token?.rtk) {
        header = {
          headers: {
            Authorization: "Bearer " + token?.atk,
            "Content-Type": contentType,
          },
        };
      } else if (!isRoutePrivate) {
        const accessToken = await AccessToken();
        if (accessToken) {
          header = {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": contentType,
            },
          };
        } else {
          toast.error(
            "Internal error occured while generating Access Token. Kindly retry"
          );
          return;
        }
      }
      if (header) {
        let fullAddr = `${CONFIG.API_ROOT_ADDRESS}${path}`;
        return await AxiosInstance.delete(fullAddr, header);
      } else {
        toast.error("Unauthorized access");
      }
    }
  } catch (error) {
    const errorMsg = error?.response?.data?.responseBody?.errorDescription || error?.response;
    toast.error(errorMsg);
    return error;
  }
};
