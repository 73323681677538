import React from 'react';
import Logo from '../../../../assets/HeaderLogo.svg'
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineMenu } from 'react-icons/hi';
import { IconContext } from 'react-icons/lib';
import Dropdown from '../../../../components/dropdown/Dropdown';
import NAV_ROUTES from '../../../../routes/Nav.Route';

function Navbar({ openSidebar }) {
  const navigate = useNavigate()
  return (
    <div className='mx-auto px-[5px] sm:px-[25px] md:px-[45px] lg:px[100px] h-[70px] fixed flex items-center text-center z-10 w-full bg-[#FFFFFF] box-border border-b-2 border-b-[#F2F4F7] justify-between'>
      <div className='flex gap-5 items-center text-center'>
        <Link className='flex gap-2 items-center'>
          <img src={Logo} alt=""></img>
          <p className='text-[30px] text-primary-700 font-bold'>PayMoor</p>
        </Link>

        <div>
          <ul className="hidden sm:hidden md:hidden lg:flex items-center text-gray-600 text-[16px] font-semibold gap-[16px]">
            {
              NAV_ROUTES.map(({ link, name, hasDropDown }, index) => {
                if (!name) return
                if (hasDropDown) return <Dropdown Text={name} />
                return <Link to={link}> {name} </Link>
              })
            }
          </ul>
        </div>
      </div>

      <div className=''>
        <div className='hidden sm:hidden md:hidden lg:flex gap-5 items-center text-center'>
          <Link to='/login'>Log in</Link>

          <button type="" onClick={() => navigate('/register')} className="bg-[#3658BB] text-[#FFFFFF] rounded-md px-[10px] py-[12px] cursor-pointer overflow-hidden border-none">Create account</button>
        </div>

        <div className='flex sm:flex md:flex lg:hidden' onClick={openSidebar}>
          <IconContext.Provider value={{ size: '2rem', color: '#475467' }}>
            <HiOutlineMenu />
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default Navbar