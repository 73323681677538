import React from "react";
import { SVGS } from "../../assets";

function TextInput(props) {
  const {
    name,
    label,
    widthBackground,
    errorMsg,
    isValid,
    isnotrequired = true,
    ...restProps
  } = props;
  return (
    <div className="w-full relative">
      <label
        htmlFor={name}
        className="text-[14.5px] pb-1 block text-gray-700 font-medium"
      >
        {label}
        {isnotrequired ? (
          ""
        ) : (
          <span className="text-red-700 font-semibold">*</span>
        )}
      </label>
      <input
        name={name}
        type="text"
        required
        className={`w-full h-[48px] rounded-lg shadow-sm border ${
          widthBackground ? "border-primary-50 bg-[#EFF2FA] text-gray-900" : "border-gray-[#D0D5DD] bg-white text-gray-500"
        } focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 outline-none px-3.5 text-[14.5px]`}
        {...restProps}
      />
      {isValid && (
        <span className="absolute right-2 top-9 pt-1">
          <SVGS.CheckedCircle />
        </span>
      )}
      <p
        className={`error__msg text-sm text-gray-500 ${
          errorMsg ? "flex" : "hidden"
        }`}
      >
        {errorMsg}
      </p>
    </div>
  );
}

export default TextInput;
