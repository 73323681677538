import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { RiCloseLine } from 'react-icons/ri'
import './Sidebar.css';
import { IconContext } from 'react-icons/lib';


const toggleClass = ({ isActive }) => isActive ? '' : ''
function Sidebar({ sidebar, close }) {
    return (
        <>
            <div className={sidebar ? "sidebar sidebar--open" : "sidebar"}>
                <div className="sidebar-header">
                    <div>
                        <IconContext.Provider value={{ size: '2rem', color: '#475467' }} onClick={close}>
                            <RiCloseLine onClick={close}/>
                        </IconContext.Provider>
                    </div>
                </div>
                <div className='sidebar-body'>

                </div>
            </div>
        </>
    )
}

export default Sidebar
