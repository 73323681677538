import React from "react";
import { Icons } from "../../assets/icons";
import ToggleSwitchAccountOwn from "../../components/toggle-switch/ToggleSwitchAccountOwn";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function AgentSecurityTab({ userData }) {
  const navigateTo = useNavigate();
  const { deleteAccountOwner } = useAccountOwnersRequest();
  const deleteAccount = async () => {
    const payload = {
      id: userData?.profile?.msisdn,
      type: "MSISDN"
    };
    if (payload.id) {
      const response = await deleteAccountOwner(payload);
      if (response.includes("success")) {
        toast.success(response);
        navigateTo("/admin/manage-admins");
      } else {
        toast.error("Failed");
      }
    }
  };
  return (
    <div className="user-table-container w-full h-[60vh] mt-2 px-7 py-3 bg-[#FCFCFD]">
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-x-2 mt-6 bg-[#EFF2FA] w-[343px] h-[72px] px-4 py-2 justify-between rounded-md">
          <p className="text-sm font-semibold text-gray-600">Block User</p>
          <ToggleSwitchAccountOwn
            id={userData?.profile?.id}
            status={userData?.profile?.status === "ACTIVE" ? false : true}
          />
        </div>
        {/* <div className="flex items-center gap-x-2 mt-6 bg-[#EFF2FA] w-[343px] h-[72px] px-4 py-2 justify-between rounded-md">
          <p className="text-sm font-semibold text-gray-600">Delete Account</p>
          <button onClick={deleteAccount} className="">
            <Icons.TrashIcon className="" />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default AgentSecurityTab