import React from "react";
import TransactionTable from "../../components/table/transactionTable/TransactionTable";

function MerchantTransactionTab({ transactions }) {

  return (
    <div className="user-table-container w-full h-[60vh] mt-2 px-7 py-3 bg-[#FCFCFD]">
      <TransactionTable tableData={transactions} />
    </div>
  )
}

export default MerchantTransactionTab