import React, { useEffect, useState } from "react";
import GoogleButton from "../../components/button/GoogleButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import { Link } from "react-router-dom";
import PasswordInput from "../../components/input/PasswordInput";
import TextInput from "../../components/input/TextInput";
import { SVGS } from "../../assets";
import RadioButton from "../../components/button/RadioButton";
import EmailInput from "../../components/input/EmailInput";
import useRegisterRequest from "../../services/request-hooks/authorization/useRegisterRequest";

const ButtonsList = [
  { name: "AGENT", id: 1 },
  { name: "BUSINESS", id: 2 },
  { name: "CUSTOMER", id: 3 },
];
function FormGroupRegister() {
  const [errorMsgs] = useState({});
  const [tabIndex, setTabIndex] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [payload, setPayload] = useState({
    accountOwnerType: "",
    name: "",
    mobile: "",
    dateOfBirth: "09/09/2009",
    username: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (payload.accountOwnerType !== "BUSINESS") {
      let _payload = payload;
      delete _payload.businessEmailAddress;
      delete _payload.businessRegistrationNumber;
      delete _payload.businessName;
      delete _payload.companyType;

      setPayload(_payload);
    }
  }, [payload, payload.accountOwnerType]);

  const { isLoading, registerHandler } = useRegisterRequest(payload);

  const updatePayload = (e) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const updatePayloadType = (value) => {
    setPayload({
      ...payload,
      accountOwnerType: value,
    });
  };

  const gotoNextTab = () => {
    setTabIndex(2);
  };

  return (
    <div className="w-[360px]">
      {
        <>
          <div className="mb-16">
            <SVGS.ProgressTab1 />
          </div>

          {tabIndex === 1 && (
            <React.Fragment>
              <h4 className="text-3xl font-semibold text-gray-900">
                Select account type
              </h4>
              <p className="text-[16px] text-gray-600 mt-1 hidden">
                How do you want to use PayMoor?
              </p>
              <div className="form__group__1 mt-5">
                <div className="my-5">
                  {ButtonsList.map(({ name, id }) => (
                    <RadioButton
                      key={id}
                      clickHandler={updatePayloadType}
                      typeName={name}
                      id={id}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  ))}
                </div>

                <div className="my-5">
                  <PrimaryButton
                    isActive={payload.accountOwnerType !== ""}
                    isContinueBtn={true}
                    text="Continue"
                    onClick={gotoNextTab}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {tabIndex === 2 && (
            <form
              onSubmit={(e) => {
                registerHandler(e);
              }}
              autoComplete="on"
            >
              <div className="form__group__1 mt-5">
                {payload.accountOwnerType === "BUSINESS" && (
                  <>
                    <div className="my-5">
                      <TextInput
                        onChange={updatePayload}
                        name="businessRegistrationNumber"
                        label="Business Registration Number"
                        value={payload.businessRegistrationNumber}
                        errorMsg={errorMsgs.businessRegistrationNumber}
                        placeholder="Select your Business Registration Number"
                      />
                    </div>
                    <div className="my-5">
                      <EmailInput
                        onChange={updatePayload}
                        name="businessEmailAddress"
                        label="Business Email address"
                        value={payload.businessEmailAddress}
                        errorMsg={errorMsgs.businessEmailAddress}
                        placeholder="Select your Business Email address"
                      />
                    </div>
                    <div className="my-5">
                      <TextInput
                        onChange={updatePayload}
                        name="businessName"
                        label="Business Name"
                        value={payload.businessName}
                        errorMsg={errorMsgs.businessName}
                        placeholder="Select your Business Name"
                      />
                    </div>
                    <div className="my-5">
                      <TextInput
                        onChange={updatePayload}
                        name="companyType"
                        label="Company Type"
                        value={payload.companyType}
                        errorMsg={errorMsgs.companyType}
                        placeholder="Select your Company Type"
                      />
                    </div>
                  </>
                )}
                <div className="my-5">
                  <TextInput
                    onChange={updatePayload}
                    name="name"
                    label="Name"
                    value={payload.name}
                    errorMsg={errorMsgs.name}
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="my-5">
                  <TextInput
                    onChange={updatePayload}
                    name="mobile"
                    label="Mobile number"
                    value={payload.mobile}
                    type={"number"}
                    errorMsg={errorMsgs.mobile}
                    placeholder="Enter your mobile number"
                  />
                </div>
                <div className="my-5">
                  <TextInput
                    onChange={updatePayload}
                    name="dateOfBirth"
                    label="Date of birth"
                    value={payload.dateOfBirth}
                    type={"date"}
                    errorMsg={errorMsgs.dateOfBirth}
                    placeholder="Enter your date of birth"
                  />
                </div>
                <div className="my-5">
                  <TextInput
                    onChange={updatePayload}
                    name="username"
                    label="Username"
                    value={payload.username}
                    errorMsg={errorMsgs.username}
                    placeholder="Enter preferred username"
                  />
                </div>
                <div className="my-5">
                  <PasswordInput
                    onChange={updatePayload}
                    name="password"
                    label="Password"
                    value={payload.password}
                    errorMsg={errorMsgs.password}
                    placeholder="Create a password"
                  />
                </div>
                <div className="my-5">
                  <PasswordInput
                    onChange={updatePayload}
                    name="confirmPassword"
                    label="Confirm Password"
                    value={payload.confirmPassword}
                    errorMsg={errorMsgs.confirmPassword}
                    placeholder="Type password again"
                  />
                </div>
                <div className="my-5">
                  <PrimaryButton
                    onClick={(e) => {
                      registerHandler(e);
                    }}
                    text="Get Started"
                    isLoading={isLoading}
                    isActive={
                      Object.keys(payload).filter((key) => payload[key] === "")
                        .length === 0
                    }
                  />
                </div>
                <div className="my-5 hidden">
                  <GoogleButton />
                </div>
              </div>
            </form>
          )}
          <div className="my-5 flex items-center justify-center gap-1">
            <p className="text-gray-700 text-sm">Already have an account?</p>
            <Link
              to="/login"
              className="font-semibold text-primary-700 text-sm"
            >
              Log in
            </Link>
          </div>
        </>
      }
    </div>
  );
}

export default FormGroupRegister;
