import React from "react";
import { SVGS } from "../../assets";

function EmailInput(props) {
  const { name, value, label, errorMsg, isValid, ...restProps } = props;
  return (
    <div className="w-full relative">
      <label
        htmlFor={name}
        className="text-[14.5px] pb-1 block text-gray-700 font-medium"
      >
        {label}
        {props.isnotrequired ? (
          ""
        ) : (
          <span className="text-red-700 font-semibold">*</span>
        )}
      </label>
      <input name={name}
        type="text"
        required
        className={`w-full h-[44px] rounded-lg shadow-sm border border-gray-[#D0D5DD] focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 text-gray-500 outline-none px-3.5 text-[14.5px]`}
        {...restProps}
      />
      {isValid && (
        <span className="absolute right-2 top-9 pt-1">
          <SVGS.CheckedCircle />
        </span>
      )}
       <p
        className={`error__msg text-sm text-gray-500 ${
          errorMsg ? "flex" : "hidden"
        }`}
      >
        {errorMsg}
      </p>
    </div>
  );
}

export default EmailInput;
