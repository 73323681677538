import React from 'react'
import DropdownItem from './DropdownItem';
import { FiBook } from 'react-icons/fi';
import { GiStarsStack } from 'react-icons/gi';
import { IoMdHelpBuoy } from 'react-icons/io'
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { CgFileDocument } from 'react-icons/cg'
import { IconContext } from 'react-icons/lib';


const dropdownItems = [
    {
        icon: <FiBook />,
        name: "Blog",
        subtxt: "The latest industry news, updates and info.",
        subcomponents: ''
    },
    {
        icon: <GiStarsStack />,
        name: "Customer stories",
        subtxt: "Learn how our customers are making big changes."
    },
    {
        icon: <AiOutlinePlayCircle />,
        name: "Video tutorials",
        subtxt: "Get up and running on new features and techniques."
    },
    {
        icon: <CgFileDocument />,
        name: "Documentation",
        subtxt: "All the boring stuff that you (hopefully won’t) need."
    },
    {
        icon: <IoMdHelpBuoy />,
        name: "Help and support",
        subtxt: "Learn, fix a problem, and get answers to your questions."
    },

]

function DropdownList() {
    return (
        <div className='dropdown-item-card'>
            <div className='dropdown-item-card-txt'>
                {
                    dropdownItems.map(
                        ({ icon, name, subtxt }, index) => <DropdownItem icon={icon} name={name} subtxt={subtxt}/>
                    )
                }
            </div>
            <div className='dropdown-item-card-img'>

            </div>
        </div>
    )
}

export default DropdownList