import React from "react";
import { SVGS } from "../../assets";
import { Icons } from "../../assets/icons";

function Navbar({ user }) {
  return (
    <div className="h-24 w-full flex items-center justify-between px-1">
      <div className="flex items-center">
        <h5 className="text-xl font-semibold">
          Hi, {user?.username ?? "N/A"} 👋🏽
        </h5>
        <div className="w-[280px] mx-7 h-11 relative bg-primary-100 p-0.5 rounded-[10px] flex items-center justify-center">
          <span className="absolute flex w-[18px] top-[12px] left-[12px]">
            <SVGS.SearchIcon />
          </span>
          <input
            type="text"
            placeholder="Search"
            className="w-full h-full leading-[48px] text-[18px] transition-all duration-500 pl-8 border border-gray-300 outline-none focus:border-primary-300 rounded-lg"
          />
        </div>
      </div>
      <span>
        <Icons.BellIcon />
      </span>
    </div>
  );
}

export default Navbar;
