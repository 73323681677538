import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function useWaitBeforeRedirect() {
  const [redirectPath, setRedirectPath] = useState("");
  const navigateTo = useNavigate();
  useEffect(() => {
    if (redirectPath) {
      setTimeout(() => {
        navigateTo(redirectPath);
        // window.location.href = redirectPath;
      }, 1000);
    }
  }, [navigateTo, redirectPath]);

  return { setRedirectPath };
}

export default useWaitBeforeRedirect;
