import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

function MultiSelectOption(props) {
  const {
    options,
    widthBackground,
    isnotrequired = false,
    onChange,
    label = "",
    name,
    ...restProps
  } = props;
  const [selected, setSelected] = useState([]);

  const handleSelected = (e) => {
    setSelected(e)
    onChange(e.map(({ value }) => value))
  }

  return (
    <div className="w-full relative">
      <label
        htmlFor={name}
        className="text-[14.5px] pb-1 block text-gray-700 font-medium"
      >
        {label}
        {isnotrequired ? (
          ""
        ) : (
          <span className="text-red-700 font-semibold">*</span>
        )}
      </label>
      <MultiSelect
        name="select"
        value={selected}
        onChange={handleSelected}
        required={!isnotrequired}
        {...restProps}
        options={options}
        className={`w-full h-[48px] rounded-lg ${widthBackground
          ? "border-primary-50 bg-[#EFF2FA] text-gray-900"
          : "border-gray-[#D0D5DD] bg-white text-gray-500"
          } shadow-sm border border-gray-[#D0D5DD] focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 text-gray-500 outline-none px-3.5 text-[14.5px]`}
        optionClassName="py-2 px-3 cursor-pointer hover:bg-primary-600"
        selectedItemsClassName="bg-primary-600 rounded-full py-1 px-2 m-1 inline-flex items-center"
        removeSelectedItemClassName="ml-2 cursor-pointer"
      />
    </div>
  );
}

export default MultiSelectOption;
