import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import TextInput from "../../input/TextInput";
import PasswordInput from "../../input/PasswordInput";
import PrimaryButton from "../../button/PrimaryButton";
import SelectInput from "../../select/SelectInput";
import { useAccountOwnersRequest } from "../../../services/request-hooks/account-owner/useAccountOwners";

function Index() {
  const { createAgent } = useAccountOwnersRequest();
  const {
    hideOverlay,
    setModalName,
    createAgentPayload,
    onCreateAgentInputChange,
  } = React.useContext(OverlayContext);
  const [isBtnActive, setIsBtnActive] = useState(false);

  useEffect(() => {
    let isAnyKeyEmpty = false;
    Object.keys(createAgentPayload).forEach((key) => {
      if (createAgentPayload[key] === "") isAnyKeyEmpty = true;
    });
    if (isAnyKeyEmpty) {
      setIsBtnActive(false);
    } else {
      setIsBtnActive(true);
    }
  }, [createAgentPayload]);

  const createAgentHandler = async () => {
    const {firstName, lastName, dateOfBirth, msisdn, username, emailAddress, password, profession, maritalStatus, gender, accountOwnerType} = createAgentPayload
    const newPayload = {
      firstName,
      lastName,
      dateOfBirth,
      msisdn,
      username,
      emailAddress,
      credential: {
        credentialValue: password,
        credentialType: "PASSWORD"
      },
      profession,
      maritalStatus,
      gender,
      accountOwnerType,
    }
    const response = await createAgent(newPayload);
    if (response) {
      setModalName("create-agent-success");
    }
  };
  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="header flex items-center h-[60px] justify-between">
          <h5 className="text-xl font-semibold">Create Agent account</h5>
          <button
            onClick={hideOverlay}
            className="bg-gray-50 w-9 h-9 rounded flex items-center justify-center"
          >
            <SVGS.CloseIcon />
          </button>
        </div>
        <hr className="bg-gray-200 mt-2" />
        <div className="form-body mt-6">
          <div className="mb-4">
            <TextInput
              value={createAgentPayload.firstName}
              onChange={onCreateAgentInputChange}
              name="firstName"
              label={"Agent first name"}
              placeholder="Enter Firstname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAgentPayload.lastName}
              onChange={onCreateAgentInputChange}
              name="lastName"
              label={"Agent Last name"}
              placeholder="Enter Lastname"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAgentPayload.username}
              onChange={onCreateAgentInputChange}
              name="username"
              label={"Agent Username"}
              placeholder="Enter Username"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAgentPayload.emailAddress}
              onChange={onCreateAgentInputChange}
              name="emailAddress"
              label={"Agent email address"}
              placeholder="Enter Email Address"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <TextInput
              value={createAgentPayload.msisdn}
              onChange={onCreateAgentInputChange}
              name="msisdn"
              label={"Agent phone no"}
              placeholder="Enter phone no"
              widthBackground={true}
            />
          </div>
          <div className="mb-4">
            <PasswordInput
              value={createAgentPayload.password}
              onChange={onCreateAgentInputChange}
              name="password"
              label={"Agent temporary password"}
              placeholder="Type Password"
              widthBackground={true}
            />
            <button className="text-primary-700 mt-1 font-semibold text-sm">
              Generate password
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <PrimaryButton
          onClick={createAgentHandler}
          isLoading={false}
          isActive={isBtnActive}
          text="Create Agent account"
        />
      </div>
    </div>
  );
}

export default Index;
