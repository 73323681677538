import React from "react";
import ToggleSwitchAccountOwn from "../../components/toggle-switch/ToggleSwitchAccountOwn";
import { Link } from "react-router-dom";

function TableRow({ customer }) {
  return (
    <div className="h-[77px] flex items-center justify-between w-full border-b border-gray-200">
      <Link to={`${customer?.msisdn}`} className="flex items-center">
        {customer?.profileImg ? (
          <img
            src={customer?.profileImg}
            className="w-9 h-9 rounded-full border border-gray-200"
            alt=""
          />
        ) : (
          <span className="w-10 h-10 rounded-full border border-gray-300 uppercase font-semibold text-center flex items-center justify-center">
            {customer?.firstName[0]}
            {customer?.lastName[0]}
          </span>
        )}
        <p className="text-sm mx-4 text-gray-600 font-medium capitalize">
          {customer?.firstName} {customer?.lastName}
        </p>
      </Link>
      <div className="flex items-center gap-x-2">
        <p className="text-[15px] text-gray-400">Block user</p>
        <ToggleSwitchAccountOwn
          id={customer?.msisdn}
          status={customer?.status === "ACTIVE" ? false : true}
        />
      </div>
    </div>
  );
}

export default TableRow;
