import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function TableRow({ roles }) {
    return (
        <div className="h-[77px] flex items-center justify-between w-full border-b border-gray-200">
            <Link to={`${roles?.id}`} className="flex items-center">
                <p className="text-base mx-4 text-gray-600 font-medium capitalize">{roles.name}</p>
            </Link>
            <div className="flex items-center gap-x-2">
                <p className="text-[15px] text-gray-400">{roles.permissions.length} permissions granted</p>
            </div >
        </div>
    )
}

export default TableRow