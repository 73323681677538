import React from "react";
import Overlay from ".";

export const OverlayContext = React.createContext();

function OverlayProvider(props) {
  const {children, ...restProps} = props;
  return (
    <OverlayContext.Provider value={{ ...restProps }}>
      <Overlay />
      {children}
    </OverlayContext.Provider>
  );
}

export default OverlayProvider;
