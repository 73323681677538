import React, { useState } from 'react';
import './Dropdown.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import DropdownList from './DropdownList';



function Dropdown({ Text }) {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className='dropdown-container'>
            <button className='dropdown-btn' onClick={() => setIsExpanded( prev => !prev)}>
                {Text}
                <IconContext.Provider value={{ size: '0.7rem', color: '#475467' }}>
                    {isExpanded ? <FaChevronUp/>: <FaChevronDown /> }
                </IconContext.Provider>
            </button>
            {
                isExpanded &&
                <div className='dropdown-item'>
                    <DropdownList />
                </div>
            }
        </div>
    )
}

export default Dropdown