import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import NotFoundPage from "./pages/error/NotFoundPage";
import { Toaster } from "react-hot-toast";
import { PUBLIC_ROUTES } from "./routes/public-routes";
import { ADMIN_PRIVATE_ROUTES } from "./routes/private-routes";
import Dashboard from "./layouts/admin-dashboard/Dashboard";
import { ADMIN_PROTECTED_ROUTES } from "./routes/protected-route";

function App() {
  const [isLoading, setIsLoading] = useState(true);//The status will be true until it completely render before it turns to false
  const [isAuthorized, setIsAuthorized] = useState(true);
  useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const isTokensFound = token && token.rtk && token.rtk && true;
    if (isTokensFound) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
    setIsLoading(false);
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <Toaster />
      <Router>
        <Routes>
          {PUBLIC_ROUTES.map(({ href, element }, index) => (
            <Route key={index} path={href} element={element} />
          ))}
          {ADMIN_PROTECTED_ROUTES.map(({ href, element }, index) => (
            <Route
              key={index}
              path={href}
              element={isAuthorized ? <Navigate to={"/admin"} /> : element}
            />
          ))}
          <Route path="/admin" element={<Dashboard />}>
            {ADMIN_PRIVATE_ROUTES.map(({ href, element }, index) => (
              <Route
                key={index}
                path={`/admin${href}`}
                element={isAuthorized ? element : <Navigate to={"/login"} />}
              />
            ))}
          </Route>
          <Route path={`/*`} element={<NotFoundPage />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
