import axios from "axios";
import { CONFIG } from "../../../config";

export const AccessToken = async () => {
  const payload = {
    identity: {
      identityValue: "webadmin",
      identityType: "USERNAME",
    },
    credential: {
      credentialValue: "ABc123456!",
      credentialType: "PASSWORD",
    },
  };
  const response = await axios.post(
    `${CONFIG.API_ROOT_ADDRESS}/token`,
    payload
  );

  return response.data.responseBody.accessToken;
};
