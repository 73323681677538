import React from 'react'

function DisabledInput(props) {
    const {
        name,
        label,
        placeholder
    } = props;
    return (
        <div className="w-full relative">
            <label
                htmlFor={name}
                className="text-[14.5px] pb-1 block text-gray-700 font-medium"
            >
                {label}
            </label>
            <input
                name={name}
                type="text"
                required
                className={`w-full h-[48px] rounded-lg shadow-sm border pl-3 border-[#D9E1F9] bg-[#F9FAFB] text-gray-500`}
                disabled
                placeholder={placeholder}
            />
        </div>
    );
};

export default DisabledInput