import React, { useEffect, useState } from "react";
import { Icons } from "../../assets/icons";
import { SVGS } from "../../assets";
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import TransactionTable from "../../components/table/transactionTable/TransactionTable";
import { useOutletContext } from "react-router-dom";
import { useRolesRequest } from "../../services/request-hooks/roles/useRoles";
import { useTransactionsRequest } from "../../services/request-hooks/transactions/useTransactions";
import { categorizeTransactions } from "../../utils/CategorizeTransaction";
import { toast } from "react-hot-toast";

const quickActions = [
  {
    name: "create-admin",
    text: "Create Admin account",
    startIcon: <Icons.ManageAdminIcon color={"#2B4696"} />,
  },
  {
    name: "create-agent",
    text: "Create Agent account",
    startIcon: <Icons.ManageAgentIcon color={"#2B4696"} />,
  },
  {
    name: "create-merchant",
    text: "Create Merchant account",
    startIcon: <Icons.ManageMerchantIcon color={"#2B4696"} />,
  },
];
function DashboardOutlet() {
  const [adminRolesSelectOptions, setAdminRolesSelectOptions] = useState([]);
  const [agentRolesSelectOptions, setAgentRolesSelectOptions] = useState([]);
  const [merchantRolesSelectOptions, setMerchantRolesSelectOptions] = useState(
    []
  );
  const { transactions } = useOutletContext();
  const [filteredTransactions, setFilteredTransactions] = useState(null);
  const { viewTransactions: _viewAllTransations } = useTransactionsRequest();

 
  const viewAllTransations = async () => {
    const payload = {
      page: 0,
      size: 100
    }
    const _transactions = await _viewAllTransations(payload);
    if (_transactions?.data?.status < 200 || _transactions?.data?.status > 204) {
      toast.error(message);
    } else {
      const categorizedTransactions = categorizeTransactions(_transactions);
      setFilteredTransactions(categorizedTransactions);
    }
  };

  useEffect(() => {
    viewAllTransations();
  }, []);


  const [modalName, setModalName] = useState("");
  const [createAdminPayload, setCreateAdminPayload] = useState({
    firstName: "",
    lastName: "",
    username: "",
    emailAddress: "",
    roleIdentifier: "",
    password: "ABc123456!",
  });
  const onCreateAdminInputChange = (e) => {
    const { name, value } = e.target;
    setCreateAdminPayload({
      ...createAdminPayload,
      [name]: value,
    });
  };
  const { viewAll } = useRolesRequest();
  const viewAllRoles = async () => {
    const _roles = await viewAll();
    let _superAdminRoles = [];
    let _superAgentRoles = [];
    let _superMerchantRoles = [];
    _roles.contents.forEach(({ id, name, roleType }) => {
      if (roleType === "SUPER_ADMINISTRATOR") {
        _superAdminRoles.push({
          value: id,
          label: name,
        });
      } else if (roleType === "AGENT") {
        _superAgentRoles.push({
          value: id,
          label: name,
        });
      } else if (roleType === "MERCHANT") {
        _superMerchantRoles.push({
          value: id,
          label: name,
        });
      }
    });
    setAdminRolesSelectOptions(_superAdminRoles);
    setAgentRolesSelectOptions(_superAgentRoles);
    setMerchantRolesSelectOptions(_superMerchantRoles);
  };
  useEffect(() => {
    viewAllRoles();
  }, []);

  const [createAgentPayload, setCreateAgentPayload] = useState({
    firstName: "",
    lastName: "",
    username: "",
    emailAddress: "",
    roleIdentifier: "",
    password: "ABc123456!",
  });
  const onCreateAgentInputChange = (e) => {
    const { name, value } = e.target;
    setCreateAgentPayload({
      ...createAgentPayload,
      [name]: value,
    });
  };
  const [createMerchantPayload, setCreateMerchantPayload] = useState({
    firstName: "",
    lastName: "",
    username: "",
    emailAddress: "",
    roleIdentifier: "",
    password: "ABc123456!",
  });
  const onCreateMerchantInputChange = (e) => {
    const { name, value } = e.target;
    setCreateMerchantPayload({
      ...createMerchantPayload,
      [name]: value,
    });
  };
  const [filterPayload, setFilterPayload] = useState({
    identityType: "",
    identity: "",
    transactionType: "",
    fromDate: "",
    toDate: "",
  });
  const onFilterInputChange = (e) => {
    const { name, value } = e.target;
    setFilterPayload({
      ...filterPayload,
      [name]: value,
    });
  };
  const onResetFilter = () => {
    setFilterPayload({
      identityType: "",
      identity: "",
      transactionType: "",
      fromDate: "",
      toDate: "",
    });
    setFilteredTransactions(null);
  };
  const createUser = (name) => {
    setModalName(name);
  };
  const hideOverlay = () => setModalName("");
  const { viewTransactions } = useTransactionsRequest();
  const filterTransactionHandler = () => {
    let payload = {
      size: 100,
      page: 0,
    };
    let isPayloadFilled = false;
    if (filterPayload.identityType) {
      if (filterPayload.identity) {
        payload.senderIdentity = {
          identityValue: filterPayload.identity,
          identityType: filterPayload.identityType,
        };
        payload.receiverIdentity = {
          identityValue: filterPayload.identity,
          identityType: filterPayload.identityType,
        };
        isPayloadFilled = true;
      } else {
        toast.error("Identity value is required");
      }
    }
    if (filterPayload.transactionType) {
      payload.financialTransactionType = filterPayload.transactionType;
      isPayloadFilled = true;
    }
    if (filterPayload.fromDate || filterPayload.toDate) {
      if (!filterPayload.toDate) {
        toast.error("To date is required in date range filter");
      } else if (!filterPayload.fromDate) {
        toast.error("To date is required in date range filter");
      } else {
        payload.fromDate = `${filterPayload.fromDate}T12:22:04.973Z`;
        payload.toDate = `${filterPayload.fromDate}T12:22:04.973Z`;
        isPayloadFilled = true;
      }
    }

    const fetchData = async () => {
      if (isPayloadFilled) {
        const { response, message } = await viewTransactions(payload);
        if (response?.data?.status < 200 || response?.data?.status > 204) {
          toast.error(message);
        } else {
          const categorizedTransactions = categorizeTransactions(response);
          setFilteredTransactions(categorizedTransactions);
          hideOverlay();
        }
      }
    };
    fetchData();
  };
  return (
    <React.Fragment>
      <OverlayProvider
        filterTransactionHandler={filterTransactionHandler}
        adminRolesSelectOptions={adminRolesSelectOptions}
        agentRolesSelectOptions={agentRolesSelectOptions}
        merchantRolesSelectOptions={merchantRolesSelectOptions}
        onResetFilter={onResetFilter}
        filterPayload={filterPayload}
        onFilterInputChange={onFilterInputChange}
        createAdminPayload={createAdminPayload}
        onCreateAdminInputChange={onCreateAdminInputChange}
        createAgentPayload={createAgentPayload}
        onCreateAgentInputChange={onCreateAgentInputChange}
        createMerchantPayload={createMerchantPayload}
        onCreateMerchantInputChange={onCreateMerchantInputChange}
        modalName={modalName}
        setModalName={setModalName}
        hideOverlay={hideOverlay}
      >
        <div className="mt-14">
          <h5 className="title font-medium text-xl">
            Quick actions <span className="mx-3">⚡</span>
          </h5>
          <div className="w-full flex items-center flex-wrap gap-2 mt-6">
            {quickActions.map(({ name, text, startIcon }, index) => (
              <button
                key={index}
                onClick={() => createUser(name)}
                className="bg-[#EFF2FA] text-gray-700 px-7 min-w-[294px] h-16 rounded-xl flex items-center justify-between font-medium ..."
              >
                <span className="flex items-center text-[16px] gap-x-3">
                  {startIcon}
                  {text}
                </span>
                <SVGS.PlusIcon color={"#2B4696"} />
              </button>
            ))}
          </div>
          <div className="mt-6 pt-2">
            <TransactionTable
              tableData={
                filteredTransactions ? filteredTransactions : transactions

              }
            />
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>
  );
}

export default DashboardOutlet;
