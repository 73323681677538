import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import TextInput from "../../input/TextInput";
import PrimaryButton from "../../button/PrimaryButton";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRolesRequest } from "../../../services/request-hooks/roles/useRoles";
import MultiSelectOption from "../../select/MultiSelectOption";
import SelectInput from "../../select/SelectInput";

function index() {
    const { create } = useRolesRequest();
    const navigateTo = useNavigate();
    const {
        hideOverlay,
        rolesPayload,
        permissionSelectOptions,
        onCreateRolesInputChange,
        rolesData
    } = React.useContext(OverlayContext);
    const [isBtnActive, setIsBtnActive] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState([]);
    const [options, setOptions] = useState([]);
    const [roleTypeOptions, setRoleTypeOptions] = useState([]);
    useEffect(() => {
        let isAnyKeyEmpty = false;
        Object.keys(rolesPayload).forEach((key) => {
            if (rolesPayload[key] === "") isAnyKeyEmpty = true;
        });
        selectedPermission.length ? isAnyKeyEmpty = false : isAnyKeyEmpty = true
        if (isAnyKeyEmpty) {
            setIsBtnActive(false);
        } else {
            setIsBtnActive(true);
        }

        setOptions(permissionSelectOptions.map(perm => ({ label: perm.description, value: perm.name })));
        const uniqueRoles = [...new Set(rolesData.map(role => role.roleType))]
        setRoleTypeOptions(uniqueRoles.map(role => ({label: role.replace(/_/g, ' '), value: role})))
    }, [rolesPayload, selectedPermission]);

    const createRolesHandler = async () => {
        const { name, roletype, description } = rolesPayload
        const newPayload = {
            name,
            description,
            roletype,
            permissions: selectedPermission,
        };
        const response = await create(newPayload);
        if (response) {
            toast.success("Role created");
            navigateTo("/admin/manage-roles");
        } else {
            toast.error("Failed");
        };
    };

    const handlePermissionsInput = (e) => {
        setSelectedPermission(e)
    }
    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="header flex items-center h-[60px] justify-between">
                    <h5 className="text-xl font-semibold">Create New Role</h5>
                    <button
                        onClick={hideOverlay}
                        className="bg-transparent w-9 h-9 rounded flex items-center justify-center"
                    >
                        <SVGS.CloseIcon />
                    </button>
                </div>
                <hr className="bg-gray-200 mt-2" />
                <div className="form-body mt-6">
                    <div className="mb-4">
                        <TextInput
                            value={rolesPayload.name}
                            onChange={onCreateRolesInputChange}
                            label={"Role name"}
                            name="name"
                            placeholder="Enter role name"
                            widthBackground={true}
                        />
                    </div>
                    <div className="mb-4">
                        <TextInput
                            value={rolesPayload.description}
                            onChange={onCreateRolesInputChange}
                            label={"Description"}
                            name="description"
                            placeholder="Enter description"
                            widthBackground={true}
                        />
                    </div>
                    <div className="mb-4">
                        <SelectInput
                            value={rolesPayload.roletype}
                            onChange={onCreateRolesInputChange}
                            label={"Role type"}
                            name="roletype"
                            placeholder="Enter role type"
                            widthBackground={true}
                            options={roleTypeOptions}
                        />
                    </div>
                    <div className="mb-4">
                        <MultiSelectOption
                            name="permissions"
                            label="permissions"
                            placeholder="Assign Permission"
                            options={options}
                            onChange={handlePermissionsInput}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <PrimaryButton
                    onClick={createRolesHandler}
                    isLoading={false}
                    isActive={isBtnActive}
                    text="Create Role"
                />
            </div>
        </div>
    );
};

export default index