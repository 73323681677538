import React from 'react';
import './Section.css';
import Simg from '../../../../assets/Simg.svg';
import WashingtonPost from '../../../../assets/WashingtonPost.svg';
import TechCrunch from '../../../../assets/TechCrunch.svg';
import Bloomberg from '../../../../assets/Bloomberg.svg';
import Gizmodo from '../../../../assets/Gizmodo.svg';
import Forbes from '../../../../assets/Forbes.svg';


function Section() {
    return (
        <div>
            <div className='section-container'>
                <div className='section-header'>
                    <div className='section-bck'>
                        <p className='section-header-txt'>Features</p>
                    </div>
                    <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>All-in-one finance for any business</h2>
                    <p className='section-header-subtxt'>Get a deposit account, credit card, and spend management software—in one refreshingly easy solution. No fees or minimums.</p>
                </div>

                <div className='section-body'>
                    <div className='section-body-img'>
                        <img src={Simg} alt="" />
                    </div>

                    {/* <div className='section-body-img-1'>
                        <img src={MSimg} alt="" />
                    </div> */}

                    <div className='section-body-txt'>
                        <div className='section-body-txt-info'>
                            <hr style={{ border: "2px solid #2B4696", borderRadius: "2px" }} />
                            <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Share team inboxes</h3>
                            <p className='section-body-txt-info-subtxt'>Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.</p>
                        </div>

                        <div className='section-body-txt-info'>
                            <hr style={{ border: "2px solid #D9E1F9", borderRadius: "2px" }} />
                            <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Share team inboxes</h3>
                            <p className='section-body-txt-info-subtxt'>Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.</p>
                        </div>

                        <div className='section-body-txt-info'>
                            <hr style={{ border: "2px solid #D9E1F9", borderRadius: "2px"}} />
                            <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[30px] tracking-tight'>Share team inboxes</h3>
                            <p className='section-body-txt-info-subtxt'>Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.</p>
                        </div>
                    </div>
                </div>

                <div className='section-footer'>
                    <p className='section-footer-txt'>We’re changing the game</p>
                    <div className='section-footer-img'>
                        <img src={WashingtonPost} alt="" />
                        <img src={TechCrunch} alt="" />
                        <img src={Bloomberg} alt="" />
                        <img src={Gizmodo} alt="" />
                        <img src={Forbes} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section