import React from "react";

function SelectInput(props) {
  const {
    type = "number",
    options,
    widthBackground,
    isnotrequired = false,
    onChange,
    label = "",
    name,
    ...restProps
  } = props;
  return (
    <div className="w-full relative">
      <label
        htmlFor={name}
        className="text-[14.5px] pb-1 block text-gray-700 font-medium"
      >
        {label}
        {isnotrequired ? (
          ""
        ) : (
          <span className="text-red-700 font-semibold">*</span>
        )}
      </label>
      <select
        name={name}
        onChange={onChange}
        type={type}
        required={!isnotrequired}
        {...restProps}
        className={`w-full h-[48px] rounded-lg ${widthBackground
          ? "border-primary-50 bg-[#EFF2FA] text-gray-900"
          : "border-gray-[#D0D5DD] bg-white text-gray-500"
          } shadow-sm border border-gray-[#D0D5DD] focus:border-primary-600 valid:border-primary-600 invalid:border-gray-[#D0D5DD] transition-all duration-500 text-gray-500 outline-none px-3.5 text-[14.5px]`}
      >
        <option defaultValue={props.placeholder} value={""}>{props.placeholder}</option>
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectInput;
