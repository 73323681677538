import React from 'react';
import './Blog.css';
import { FiArrowUpRight } from 'react-icons/fi';
import { IconContext } from 'react-icons/lib';
import Bimg1 from '../../../../assets/Bimg1.svg';
import BAimg1 from '../../../../assets/BAimg1.svg';
import Bimg2 from '../../../../assets/Bimg2.svg';
import BAimg2 from '../../../../assets/BAimg2.svg';
import Bimg3 from '../../../../assets/Bimg3.svg';
import BAimg3 from '../../../../assets/BAimg3.svg';

function Blog() {
  return (
    <div className='blog'>
      <div className='blog-container'>
        <div className='blog-header'>
          <div className='blog-title'>
            <p className='blog-title-txt'>Our blog</p>
            <h2 className='font-semibold not-italic text-[34px] text-[#101828] leading-[62px] tracking-tight'>Lastest blog posts</h2>
            <p className='blog-header-txt'>Tool and strategies modern teams need to help their companies grow.</p>
          </div>
          <div className='blog-btn'>
            <button type="" className="qblu-bck ">View all posts</button>
          </div>
        </div>

        <div className='blog-body'>
          <div className='blog-body-card'>
            <div className='blog-body-card-img'>
              <img src={Bimg1} alt="" />
            </div>
            <div className='blog-body-card-txt'>
              <p className='blog-body-card-txt-header'>Design</p>
              <div className='blog-body-card-txt-subheader' >
                <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[10px] tracking-tight'>UX review presentations</h3>
                <IconContext.Provider value={{ size: '1rem', color: '#00000' }}>
                  <FiArrowUpRight />
                </IconContext.Provider>
              </div>
              <p className='blog-body-card-subtxt'>How do you create compelling presentations that wow your colleagues and impress your managers?</p>
            </div>
            <div className='blog-body-card-footer'>
              <img src={BAimg1} alt="" />
              <div className='blog-body-card-footer-txt'>
                <p className='blog-body-card-footer-blk-txt'>Olivia Rhye</p>
                <p className='blog-body-card-footer-gry-txt'>20 Jan 2022</p>
              </div>
            </div>
          </div>

          <div className='blog-body-card'>
            <div className='blog-body-card-img'>
              <img src={Bimg2} alt="" />
            </div>
            <div className='blog-body-card-txt'>
              <p className='blog-body-card-txt-header'>Product</p>
              <div className='blog-body-card-txt-subheader' >
                <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[10px] tracking-tight'>Migrating to Linear 101</h3>
                <IconContext.Provider value={{ size: '1rem', color: '#00000' }}>
                  <FiArrowUpRight />
                </IconContext.Provider>
              </div>
              <p className='blog-body-card-subtxt'>Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.</p>
            </div>
            <div className='blog-body-card-footer'>
              <img src={BAimg2} alt="" />
              <div className='blog-body-card-footer-txt'>
                <p className='blog-body-card-footer-blk-txt'>Phoenix Baker</p>
                <p className='blog-body-card-footer-gry-txt'>19 Jan 2022</p>
              </div>
            </div>
          </div>

          <div className='blog-body-card'>
            <div className='blog-body-card-img'>
              <img src={Bimg3} alt="" />
            </div>
            <div className='blog-body-card-txt'>
              <p className='blog-body-card-txt-header'>Software Engineering</p>
              <div className='blog-body-card-txt-subheader' >
                <h3 className='font-semibold not-italic text-[20px] text-[#101828] leading-[10px] tracking-tight'>Building your API Stack</h3>
                <IconContext.Provider value={{ size: '1rem', color: '#00000' }}>
                  <FiArrowUpRight />
                </IconContext.Provider>
              </div>
              <p className='blog-body-card-subtxt'>The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.</p>
            </div>
            <div className='blog-body-card-footer'>
              <img src={BAimg3} alt="" />
              <div className='blog-body-card-footer-txt'>
                <p className='blog-body-card-footer-blk-txt'>Lana Steiner</p>
                <p className='blog-body-card-footer-gry-txt'>18 Jan 2022</p>
              </div>
            </div>
          </div>

          <div className='blog-body-btn'>
            <button type="" className="qblu-bck ">View all posts</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Blog