import React, { useEffect, useState } from "react";
import { SVGS } from "../../assets";
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import { Link, useParams } from "react-router-dom";
import AdminAccountTab from "./AdminAccountTab";
import AdminTransactionTab from "./AdminTransactionTab";
import AdminSecurityTab from "./AdminSecurityTab";
import { useUsersRequest } from "../../services/request-hooks/users/useUsers";
import { categorizeTransactions } from "../../utils/CategorizeTransaction";
import { useTransactionsRequest } from "../../services/request-hooks/transactions/useTransactions";
import { toast } from "react-hot-toast";

const tabs = [
  { name: "account", text: "Account Details" },
  { name: "transaction", text: "Transaction History" },
  { name: "security", text: "Security" },
];
function AdminDetailOutlet() {
  const { userId } = useParams();
  const [userData, setUserData] = useState({
    profile: {},
    account: [],
  });
  const [transactions, setTransactions] = useState([]);
  const { viewOne } = useUsersRequest();
  useEffect(() => {
    const viewOneHandler = async () => {
      const payload = { id: userId };
      const response = await viewOne(payload);
      setUserData({
        ...userData,
        profile: response,
        account: [
          { label: "User ID", value: response?.id },
          { label: "Email address", value: response?.emailAddress },
          { label: "Username", value: response?.username },
          { label: "Role Type", value: response?.roleType },
        ],
      });
    };
    viewOneHandler();
  }, []);

  const { viewTransactions } = useTransactionsRequest();
  useEffect(() => {
    if (userData?.profile?.username) {
      const payload = {
        senderIdentity: {
          identityValue: userData?.profile?.username,
          identityType: "USERNAME",
        },
        receiverIdentity: {
          identityValue: userData?.profile?.username,
          identityType: "USERNAME",
        },
        financialTransactionType: "P2P_TRANSFER",
        page: 0,
        size: 100,
      };
      const fetchData = async () => {
        const { response, message } = await viewTransactions(payload);
        if (response?.data?.status < 200 || response?.data?.status > 204) {
          toast.error(message);
        } else {
          const categorizedTransactions = categorizeTransactions(response);
          setTransactions(categorizedTransactions);
        }
      };
      fetchData();
    }
  }, [userData]);

  const [activeTab, setActiveTab] = useState("account");

  const openTab = (name) => {
    setActiveTab(name);
  };
  return (
    <React.Fragment>
      <OverlayProvider>
        <div className="mt-14">
          <div className="title font-medium flex items-center text-xl">
            <span>
              <SVGS.HomeIcon />
            </span>{" "}
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <Link
              to={"/admin/manage-admins"}
              className="text-sm font-medium text-primary-700"
            >
              Manage Admins
            </Link>
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <span className="text-sm font-medium text-primary-700">
              {userData?.profile?.firstName}
            </span>
          </div>
          <div className="w-full mt-10">
            <div className="tab-container flex items-center">
              {tabs.map(({ name, text }, index) => (
                <button
                  key={index}
                  onClick={() => openTab(name)}
                  className={`tab h-11 px-5 text-sm font-medium flex items-center justify-center transition-all duration-500 ${
                    activeTab === name
                      ? "bg-primary-50 text-primary-700 border-b-2 border-primary-700"
                      : "bg-white text-gray-500 border-b-2 border-gray-100"
                  }`}
                >
                  {text}
                </button>
              ))}
            </div>
            {activeTab === "account" && <AdminAccountTab userData={userData} />}
            {activeTab === "transaction" && (
              <AdminTransactionTab transactions={transactions} />
            )}
            {activeTab === "security" && <AdminSecurityTab userData={userData}/>}
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>
  );
}

export default AdminDetailOutlet;
