import { formatTime } from "./FormatTime";
import { formatDate } from "./FormatDate";
import { formatCurrency } from "./FormatCurrency";

export function categorizeTransactions(transactions) {
  const today = [];
  const yesterday = [];
  const others = [];

  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  for (let i = 0; i < transactions?.length; i++) {
    const transaction = transactions[i];
    const reformattedTransactions = {
      avatar: "",
      name: `${transaction?.sendingAccountOwner?.firstName} ${transaction?.sendingAccountOwner?.lastName}`,
      time: formatTime(transaction?.transactionDateTime),
      date: formatDate(transaction?.transactionDateTime),
      isInflow: false,
      amount: formatCurrency(transaction?.transactionAmount?.amount),
      currency: "₦",
      transactionType: transaction?.financialTransactionType,
      recipientNo: transaction?.receivingIdentity.identityValue,
      transactionStatus: transaction?.financialTransactionStatus,
      recipientName: `${transaction?.receivingAccountOwner?.firstName} ${transaction?.receivingAccountOwner?.lastName}`,
      transactionId: transaction?.transactionReference,
      description: transaction?.senderMessage
    };
    const transactionDate = new Date(transactions[i].transactionDateTime);
    if (transactionDate.toDateString() === todayDate.toDateString()) {
      today.push(reformattedTransactions);
    } else if (
      transactionDate.toDateString() === yesterdayDate.toDateString()
    ) {
      yesterday.push(reformattedTransactions);
    } else {
      others.push(reformattedTransactions);
    }
  }

  return {
    today,
    yesterday,
    others,
  };
}
