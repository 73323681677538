import React, { useState } from 'react';
import Blog from './screen/blog/Blog';
import Ciation from './screen/ciation/Ciation';
import Features from './screen/features/Features';
import Header from './screen/header/Header';
import Newsletter from './screen/newsletter/Newsletter';
import Quote from './screen/quote/Quote';
import Section from './screen/section/Section';
import Footer from './screen/footer/Footer';
import NavbarHeader from "./components/navbar/NavbarHeader"
import Navbar from '../../components/navbar/Navbar';
import Backdrop from '../../components/sidebar/Backdrop';
import Sidebar from '../../components/sidebar/Sidebar';


function Landingpage() {
  const [sidebar, setSidebar] = useState(false);

  const toggleSideBar = () => {
    setSidebar(prev => !prev)
  }

  return (
    <div>
      <NavbarHeader openSidebar={toggleSideBar} />
      <Backdrop sidebar={sidebar} />
      <Sidebar sidebar={sidebar} close={toggleSideBar} />
      <Header />
      <Features /> {/* done */}
      <Quote />
      <Ciation />
      <Section />
      <Blog />
      <Newsletter />
      <Footer />

    </div>
  )
}

export default Landingpage