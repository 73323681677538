import axios from "axios";
import { CONFIG } from "../../config";
import { useRefreshTokenRequest } from "../request-hooks/accessToken/useRefreshToken";

const AxiosInstance = axios.create({
  baseURL: CONFIG.API_ROOT_ADDRESS,
  timeout: 30000,
});

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data.responseBody.errorDescription ===
        "Access denied. Your access token is invalid and/or expired"
    ) {
      const payload = {
        identity: {
          identityValue: JSON.parse(sessionStorage.getItem("user")).name,
          identityType: "USERNAME",
        },
        credential: {
          credentialValue: "",
          credentialType: "PASSWORD",
        },
        refreshToken: JSON.parse(sessionStorage.getItem("token")).rtk,
      };
      const { Refresh } = useRefreshTokenRequest();
      const { atk: accessToken } = Refresh(payload);
      // window.location.reload();
      if (accessToken) {
        // Create a new request config with the new access token
        const config = error.config;
        config.headers.Authorization = `Bearer ${accessToken}`;
        // Retry the request with the new access token
        return AxiosInstance(config);
      }
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
