import React from "react";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";
import DetailsCard from "../../components/card/DetailsCard";
import { Icons } from "../../assets/icons";

function AdminAccountTab({ userData }) {
  return (
    <div className="user-table-container w-full h-[60vh] mt-2 px-7 py-3 bg-[#FCFCFD]">
      <div className="flex flex-col items-center justfiy-center">
        <span>
          <Icons.Avatar />
        </span>
        <h5 className="flex items-center text-[16px] font-semibold text-gray-500">
          {userData?.profile?.firstName} {userData?.profile?.lastName}{" "}
          <Icons.BlueTickIcon />
        </h5>

        <div className="w-full flex justify-center">
          <div className="w-[700px] grid grid-cols-2 gap-5 mt-7">
            {userData?.account?.map(({ label, value }, index) => (
              <DetailsCard key={index} label={label} value={value} />
            ))}
          </div>
        </div>
        <div className="flex items-center gap-x-2 mt-6">
          <p className="text-sm font-semibold text-red-600">Block User</p>
          <ToggleSwitch
            id={userData?.profile?.id}
            status={userData?.profile?.status === "ACTIVE" ? false : true}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminAccountTab;
