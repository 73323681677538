import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SVGS } from "../../assets/index";
import HeaderLogo from "../../assets/HeaderLogo.svg";
import FormGroupRegister from "./FormGroup.register";

function LeftGridRegister() {
  const [thisYear, setThisYear] = useState("");

  useEffect(() => {
    const date = new Date();
    setThisYear(date.getFullYear());
  }, []);

  return (
    <div className="w-full h-screen px-8 py-6 flex flex-col justify-between overflow-hidden overflow-y-scroll custom__scrollbar">
      <nav className="w-full h-12 flex items-center">
      <Link to="/" className='flex gap-2 items-center'>
          {/* <SVGS.BrandLogo /> */}
          <img src={HeaderLogo} alt="" />
          <p className='text-[30px] text-primary-700 font-bold'>PayMoor</p>
        </Link>
      </nav>
      <div className="formgroup__container my-16 flex justify-center">
        <FormGroupRegister />
      </div>
      <footer className="flex items-center justify-between">
        <p className="text-sm text-gray-600 flex items-center gap-1">© PayMoor {thisYear}</p>
        <p className="text-sm text-gray-600 flex items-center gap-1">
          <SVGS.EmailIcon /> help@paymoor.com
        </p>
      </footer>
    </div>
  );
}

export default LeftGridRegister;
