import { useState } from "react";
import { PostHttp } from "../../https/PostHttp";
import { toast } from "react-hot-toast";

export const useRefreshTokenRequest = () => {
  const Refresh = async (payload) => {
    try {
      const response = await PostHttp("/token", payload);
      if (response?.status >= 200 && response?.status <= 204) {
        const { data } = response;
        const { refreshToken, accessToken, roleType } = data.responseBody;
        if (roleType === "SUPER_ADMINISTRATOR") {
          const token = {
            atk: accessToken,
            rtk: refreshToken,
          };
          window.sessionStorage.setItem("token", JSON.stringify(token));
          return token;
        } else {
          toast.error(
            "You can only login as a super administrator on the web for now."
          );
        }
      } else {
      }
    } catch (error) {
      console.log(error);
      toast.error("Server error");
    }
  };
  return { Refresh };
};
