import React, { useEffect, useState } from "react";
import { SVGS } from '../../assets';
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import TableRow from "./TableRow";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";

function ManageCustomerOutlet() {
  const [customers, setCustomers] = useState([]);
  const { viewDetails } = useAccountOwnersRequest();

  async function fetchAccounts() {
    const payload = {
      page: 0,
      size: 100,
      type: "CUSTOMER"
    }
    let allCustomerAccounts = await viewDetails(payload);
    console.log({allCustomerAccounts})
    setCustomers(allCustomerAccounts);
    setCustomerData(allCustomerAccounts);
  };

  useEffect(() => {
    fetchAccounts();
    
  }, []);
  
  const [customerData, setCustomerData] = useState(null);
  const [customerPayload, setCustomerPayload] = useState({});
  const searchCustomer = (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const filteredData = customers.filter((customer) =>
        customer.username.toLowerCase().includes(value.toLowerCase())
      );
      setCustomerData(filteredData);
    } else {
      setCustomerData(customers);
      console.log({customerData});
    }
  };
  return (
    <React.Fragment>
      <OverlayProvider
        fetchAccounts={fetchAccounts}
        customerPayload={customerPayload}
        setCustomerPayload={setCustomerPayload}
      >
        <div className="mt-14">
          <div className="title font-medium flex items-center text-xl">
            <span>
              <SVGS.HomeIcon />
            </span>{" "}
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <span className="text-sm font-medium text-primary-700">
              Manage Customers
            </span>
          </div>
          <div className="w-full mt-10">
            <div className="header flex items-center justify-between">
              <h5 className="text-lg font-medium">Customers</h5>
              <div className="flex items-center">
                <div className="w-[323px] mx-5 h-11 relative">
                  <span className="absolute flex w-[18px] top-[12px] left-[12px]">
                    <SVGS.SearchIcon />
                  </span>
                  <input
                    onChange={searchCustomer}
                    type="text"
                    placeholder="Search"
                    className="w-full h-full leading-[48px] text-[16px] transition-all duration-500 pl-8 bg-gray-50 border border-gray-300 outline-none focus:border-primary-300 rounded-lg"
                  />
                </div>
              </div>
            </div>
            <div className="user-table-container w-full h-[60vh] mt-6 px-7 py-2.5 bg-gray-50">
              {customerData?.map((customer) => (
                <TableRow key={customer.id} customer={customer} />
              ))}
            </div>
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>
  )
}

export default ManageCustomerOutlet