import React from "react";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";
import { Link } from "react-router-dom";

function TableRow({ admin }) {
  return (
    <div className="h-[77px] flex items-center justify-between w-full border-b border-gray-200">
      <Link to={`${admin?.id}`} className="flex items-center">
        {admin?.profileImg ? (
          <img
            src={admin?.profileImg}
            className="w-9 h-9 rounded-full border border-gray-200"
            alt=""
          />
        ) : (
          <span className="w-10 h-10 rounded-full border border-gray-300 uppercase font-semibold text-center flex items-center justify-center">
            {admin?.firstName[0]}
            {admin?.lastName[0]}
          </span>
        )}
        <p className="text-sm mx-4 text-gray-600 font-medium capitalize">
          {admin?.firstName} {admin?.lastName}
        </p>
      </Link>
      <div className="flex items-center gap-x-2">
        <p className="text-[15px] text-gray-400">Block user</p>
        <ToggleSwitch
          id={admin?.id}
          status={admin?.status === "ACTIVE" ? false : true}
        />
      </div>
    </div>
  );
}

export default TableRow;
