import React, { useEffect, useState } from "react";
import { SVGS } from '../../assets';
import OverlayProvider from "../../components/overlay/Overlay/OverlayContext";
import TableRow from "./TableRow";
import { useAccountOwnersRequest } from "../../services/request-hooks/account-owner/useAccountOwners";

function ManageAgentOutlet() {
  const [agents, setAgents] = useState([]);
  const { viewDetails } = useAccountOwnersRequest();

  async function fetchAccounts() {
    const payload = {
      page: 0,
      size: 100,
      type: "AGENT"
    }
    let allAgentAccounts = await viewDetails(payload);
    setAgents(allAgentAccounts);
    setAgentData(allAgentAccounts);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const [agentData, setAgentData] = useState(null);
  const [modalName, setModalName] = useState("");
  const [agentPayload, setAgentPayload] = useState({});
  const [createAgentPayload, setCreateAgentPayload] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "09/09/2019",
    msisdn: "",
    username: "",
    emailAddress: "",
    password: "ABc123456!",
    profession: "Astronaut",
    maritalStatus: "SINGLE",
    gender: "MALE",
    accountOwnerType: "AGENT",
  });

  const onCreateAgentInputChange = (e) => {
    const { name, value } = e.target;
    setCreateAgentPayload({
      ...createAgentPayload,
      [name]: value,
    });
    console.log({onCreateAgentInputChange});
  };
  const hideOverlay = () => setModalName("");
  const showCreateAgent = () => setModalName("create-agent");
  const searchAgent = (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const filteredData = agents.filter((agent) =>
        agent.username.toLowerCase().includes(value.toLowerCase())
      );
      setAgentData(filteredData);
    } else {
      setAgentData(agents);
    }
  };
  return (
    <React.Fragment>
      <OverlayProvider
        fetchAccounts={fetchAccounts}
        agentPayload={agentPayload}
        setAgentPayload={setAgentPayload}
        createAgentPayload={createAgentPayload}
        onCreateAgentInputChange={onCreateAgentInputChange}
        setCreateAgentPayload={setCreateAgentPayload}
        modalName={modalName}
        setModalName={setModalName}
        hideOverlay={hideOverlay}
      >
        <div className="mt-14">
          <div className="title font-medium flex items-center text-xl">
            <span>
              <SVGS.HomeIcon />
            </span>{" "}
            <span className="mx-3">
              <SVGS.CheveronRight />
            </span>
            <span className="text-sm font-medium text-primary-700">
              Manage Agents
            </span>
          </div>
          <div className="w-full mt-10">
            <div className="header flex items-center justify-between">
              <h5 className="text-lg font-medium">Agents</h5>
              <div className="flex items-center">
                <div className="w-[323px] mx-5 h-11 relative">
                  <span className="absolute flex w-[18px] top-[12px] left-[12px]">
                    <SVGS.SearchIcon />
                  </span>
                  <input
                    onChange={searchAgent}
                    type="text"
                    placeholder="Search"
                    className="w-full h-full leading-[48px] text-[16px] transition-all duration-500 pl-8 bg-gray-50 border border-gray-300 outline-none focus:border-primary-300 rounded-lg"
                  />
                </div>
                <button
                  onClick={showCreateAgent}
                  className="w-[125px] bg-primary-700 rounded-lg h-11 text-white text-sm flex items-center justify-center"
                >
                  Add Agent
                  <span>
                    <SVGS.PlusIcon />
                  </span>
                </button>
              </div>
            </div>
            <div className="user-table-container w-full h-[60vh] mt-6 px-7 py-2.5 bg-gray-50">
              {agentData?.map((agent) => (
                <TableRow key={agent.id} agent={agent} />
              ))}
            </div>
          </div>
        </div>
      </OverlayProvider>
    </React.Fragment>

  )
}

export default ManageAgentOutlet