import useWaitBeforeRedirect from "../../../utils/WaitBeforeRedirect";
import toast from "react-hot-toast";
import RevalidateToken from "../../../utils/RevalidateToken";
import { useState } from "react";
import { PostHttp } from "../../https/PostHttp";

function useRegisterRequest(registerPayload) {
  const { isLoading, setLoading } = useState(false);
  const [errorMsgs, setErrorMsgs] = useState({});
  const { setRedirectPath } = useWaitBeforeRedirect();

  const registerHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    // Reset error states to ""
    setErrorMsgs({
      accountOwnerType: "",
      name: "",
      mobile: "",
      username: "",
      password: "",
      confirmPassword: "",
    });

    let _errorMsgs = {};
    let errorFound = false;
    Object.keys(registerPayload).forEach((key) => {
      if (registerPayload[key] === "") {
        errorFound = true;
        _errorMsgs[key] = `${key} field is required`;
      }
    });

    if (errorFound) {
      setErrorMsgs(_errorMsgs);
    } else {
      // Verify password
      const pwdRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,16}$/;
      const isPwdValid = pwdRegex.test(registerPayload.password);
      if (!isPwdValid) {
        setErrorMsgs({
          password:
            "Password must have uppercase & lowercase letters, number and special character.",
        });
      }
      // Verify password match
      else if (registerPayload.confirmPassword !== registerPayload.password) {
        setErrorMsgs({
          confirmPassword: "Password does not match.",
        });
      } else {
        // Verify email or mobile number
        const hasDigit = /^(?=.*\d).+$/;
        //   const emailRegex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
        //   const isNotEmail = !emailRegex.test(payload.mobile);
        if (registerPayload.mobile.length !== 13) {
          setErrorMsgs({
            mobile: "Your entry doesn't have 234 or not a valid mobile number.",
          });
        } else if (
          registerPayload.name.length < 3 ||
          registerPayload.name.length > 25
        ) {
          setErrorMsgs({
            name: "Length must not be less than 3 or more than 25.",
          });
        } else if (hasDigit.test(registerPayload.name)) {
          setErrorMsgs({
            name: "Name must not have a digit.",
          });
        } else if (
          registerPayload.username.length < 3 ||
          registerPayload.username.length > 15
        ) {
          setErrorMsgs({
            username: "Length must not be less than 3 or more than 15.",
          });
        } else {
          const date = registerPayload.dateOfBirth.split("-");
          let cleanPayload = {
            firstName: registerPayload.name.split(" ")[0],
            middleName:
              registerPayload.name.split(" ").length > 2
                ? registerPayload.name.split(" ")[1]
                : "",
            lastName:
              registerPayload.name.split(" ").length > 2
                ? registerPayload.name.split(" ")[2]
                : registerPayload.name.split(" ")[1],
            msisdn: registerPayload.mobile,
            username: registerPayload.username,
            credential: {
              credentialValue: registerPayload.password,
              credentialType: "PASSWORD",
            },
            gender: "MALE",
            accountOwnerType: registerPayload.accountOwnerType,
            dateOfBirth: `${date[2]}/${date[1]}/${date[0]}`,
          };

          const emailRegex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
          const isNotEmail = !emailRegex.test(
            registerPayload.businessEmailAddress
          );

          if (isNotEmail) {
            setErrorMsgs({
              businessEmailAddress: "The business email address is not valid",
            });
          } else {
            if (registerPayload.accountOwnerType === "BUSINESS") {
              cleanPayload.business = {
                businessRegistrationNumber:
                  registerPayload.businessRegistrationNumber,
                businessName: registerPayload.businessName,
                companyType: registerPayload.companyType,
                businessEmailAddress: registerPayload.businessEmailAddress,
              };
            }
          }

          // Make API CALL to register user
          try {
            const { response } = PostHttp("/accountowner", cleanPayload);
            if (response?.status === 200) {
              const { data } = response.data;

              toast.success(data.responseDescription);
              setLoading(false);
              setRedirectPath(`/verify-otp?mobile=${registerPayload.mobile}`);
            } else {
              setLoading(false);
            }
          } catch (error) { console.log(error)
            setLoading(false);
            RevalidateToken(error);
          }
        }
      }
    }
  };

  return { isLoading, registerHandler, errorMsgs };
}

export default useRegisterRequest;
