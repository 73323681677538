import React, { useEffect, useState } from "react";
import { SVGS } from "../../../assets";
import { OverlayContext } from "../Overlay/OverlayContext";
import DisabledInput from "../../input/DisabledInput";
import { useRolesRequest } from "../../../services/request-hooks/roles/useRoles";

function index({ }) {
    const { create } = useRolesRequest();
    const {
        hideOverlay,
        receiptPayload,
        onCreateReceiptInputChange,
    } = React.useContext(OverlayContext);
    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="header flex items-center h-[60px] justify-between">
                    <h5 className="text-xl font-semibold">Receipt</h5>
                    <button
                        onClick={hideOverlay}
                        className="bg-transparent w-9 h-9 rounded flex items-center justify-center"
                    >
                        <SVGS.CloseIcon />
                    </button>
                </div>
                <hr className="bg-gray-200 mt-2" />
                <div className="form-body mt-6">
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.financialTransactionType}
                            onChange={onCreateReceiptInputChange}
                            label={"Transaction type"}
                            placeholder={receiptPayload.transactionType}
                        />
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.transactionAmount.amount}
                            onChange={onCreateReceiptInputChange}
                            label={"Amount"}
                            placeholder={`${receiptPayload.currency} ${receiptPayload.amount}`}
                        />
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.receivingAccountOwner.msisdn}
                            onChange={onCreateReceiptInputChange}
                            label={"Recipient"}
                            placeholder={receiptPayload.recipientNo}
                        />
                        <p className="uppercase text-[#475467] mt-1 font-semibold text-sm flex">
                            {receiptPayload.recipientName}
                        </p>
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.senderMessage}
                            onChange={onCreateReceiptInputChange}
                            label={"Transaction status"}
                            placeholder={receiptPayload.transactionStatus}
                        />
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.transactionReference}
                            onChange={onCreateReceiptInputChange}
                            label={"Transaction ID"}
                            placeholder={receiptPayload.transactionId}
                        />
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.description}
                            onChange={onCreateReceiptInputChange}
                            label={"Description"}
                            placeholder={receiptPayload.description}
                        />
                    </div>
                    <div className="mb-4">
                        <DisabledInput
                            // value={receiptPayload.transactionDateTime}
                            onChange={onCreateReceiptInputChange}
                            label={"Date"}
                            placeholder={receiptPayload.date}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index