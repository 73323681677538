import { useState } from "react";
import { toast } from "react-hot-toast";
import { GetHttp } from "../../https/GetHttp";
import { PostHttp } from "../../https/PostHttp";

export const useTransactionsRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const viewTransactions = async (payload) => {
    setIsLoading(true);
    try {
      if (payload.page < 0 && (!payload.page || !payload.size)) {
        toast.error("Page and Size values are required");
      }
      // else if (!payload.financialTransactionType) {
      //   toast.error("Financial transaction type is required");
      // }
      else {
        const response = await PostHttp("/transactions", payload);
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          setIsLoading(false);
          return data.responseBody.contents;
        } else {
          setIsLoading(false);
          return response;
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
      return error;
    }
  };
  const viewTransactionDetails = async (payload) => {
    setIsLoading(true);
    try {
      if (!payload.transactionReference) {
        toast.error("Transaction Reference is required");
      } else {
        const response = await GetHttp(
          `/transaction/${payload.transactionReference}`
        );
        if (response?.status >= 200 && response?.status <= 204) {
          const { data } = response;
          console.log(data.responseBody);
          setIsLoading(false);
        } else {
          
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Server error");
    }
  };

  return { isLoading, viewTransactions, viewTransactionDetails };
};
