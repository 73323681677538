import React from "react";
import { SVGS } from "../../assets";

function GoogleButton(props) {
  const { text = "Sign up with Google", ...restProps } = props;
  return (
    <button
      {...restProps}
      className="w-full h-[44px] text-[16px] rounded-lg border border-gray-300 shadow-sm text-gray-700 flex items-center justify-center gap-1 font-semibold"
    >
      <SVGS.GoogleIcon />
      {text}
    </button>
  );
}

export default GoogleButton;
