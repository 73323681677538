import React from "react";
import RightGrid2 from "../../components/onboarding/RightGrid2";
import LeftGridLogin from "./LeftGrid.login";

function Login() {
  return (
    <div className="register__page__container">
      <div className="w-full h-screen grid grid-cols-1 md:grid-cols-2">
        <LeftGridLogin />
        <RightGrid2 />
      </div>
    </div>
  );
}

export default Login;
